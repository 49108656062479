import { Suspense, lazy } from "react";
import classNames from "classnames";
import { Pencil, ArrowUpDown, X } from "lucide-react";
import { IChartKey } from "../types";
import OperandSummary from "./OperandSummary";

const ReactSortable = lazy(() =>
  import("react-sortablejs").then((module) => ({
    default: module.ReactSortable,
  }))
);

interface ISelectedChartKeyListProps {
  id?: string;
  selectedChartKeys: IChartKey[];
  onRemoveSelectedMetric: (index: number) => void;
  onSortSelectedListOptions: (newList: IChartKey[]) => void;
  onClickEditChartKey: (index: number) => void;
}

export function SelectedChartKeyList({
  id,
  selectedChartKeys,
  onRemoveSelectedMetric,
  onSortSelectedListOptions,
  onClickEditChartKey,
}: ISelectedChartKeyListProps) {
  function removeSelectedMetric(index: number) {
    onRemoveSelectedMetric(index);
  }

  if (selectedChartKeys.length === 0) {
    return (
      <div className="text-sm text-gray-500">No selected items found.</div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="text-sm text-gray-500">Loading selected items...</div>
      }
    >
      <ReactSortable
        id={id}
        tag="div"
        className="grid divide-y text-sm items-center"
        list={selectedChartKeys}
        setList={onSortSelectedListOptions}
        handle=".sort-handle"
        preventOnFilter={true}
      >
        {selectedChartKeys.map((chartKey, index) => (
          <div
            className={classNames(
              "grid grid-cols-12 justify-between items-center space-x-2 py-2 px-2",
              index % 2 === 0 ? "bg-gray-100" : ""
            )}
          >
            <div className="col-span-1 sort-handle cursor-pointer group">
              <ArrowUpDown className="w-4 h-4 text-gray-300 group-hover:text-gray-500" />
            </div>
            <div className="col-span-8 tablet:col-span-10">
              <div>
                <div>{chartKey.title}</div>
                <div className="text-xs text-gray-400">{chartKey.type ?? "string"}</div>
              </div>
              {chartKey.operands?.map((operand, index) => (
                <OperandSummary
                  className="text-xs text-gray-500"
                  title={`Compare ${index + 1}: `}
                  operand={operand}
                  chartKey={chartKey}
                />
              ))}
              {chartKey.events?.map((event, index) => (
                <div className="text-xs text-gray-500">
                  Event {index + 1}:{" "}
                  <span className="font-bold">{event.type}</span>{" "}
                  <span className="font-bold">{chartKey.title}</span> call{" "}
                  <span className="font-bold">{event.func}</span> with arguments{" "}
                  <span className="font-bold">{event.args.join(", ")}</span>
                </div>
              ))}
            </div>
            <div className="col-span-3 tablet:col-span-1 inline-flex space-x-2 items-center justify-end">
              <Pencil
                className="w-4 h-4 cursor-pointer text-gray-300 hover:text-primary"
                onClick={() => onClickEditChartKey(index)}
              />
              <X
                className="w-4 h-4 cursor-pointer text-gray-300 hover:text-primary"
                onClick={() => removeSelectedMetric(index)}
              />
            </div>
          </div>
        ))}
      </ReactSortable>
    </Suspense>
  );
}
