import classNames from "classnames";
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDown } from "lucide-react";

type Item = {
  text: string;
  onClick: () => void;
};

type ButtonBaseProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  className?: string;
  items?: Item[];
};

const Button = React.forwardRef<HTMLButtonElement, ButtonBaseProps>(
  ({ className, children, items, ...props }, ref) => {
    return (
      <ButtonItems items={items}>
        <ButtonBase ref={ref} className={className} {...props}>
          {children}
        </ButtonBase>
      </ButtonItems>
    );
  }
);

const ButtonBase: React.FC<ButtonBaseProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(
        "inline-flex items-center justify-center font-medium py-4 px-8 text-lg shadow-sm uppercase bg-primary bg-gradient-to-r from-primary to-secondary border-primary hover:bg-primary-dark text-white focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-10 focus:border-primary focus:text-primary-lighter rounded-sm disabled:opacity-75 disabled:cursor-not-allowed"
      )}
    >
      {children}
    </button>
  );
};

const ButtonItems: React.FC<{ children: React.ReactNode; items?: Item[] }> = ({
  children,
  items,
}) => {
  if (!items) {
    return <>{children}</>;
  }

  return (
    <div className="inline-flex rounded-md shadow-sm">
      {children}
      <Menu as="div" className="relative -ml-px block">
        <Menu.Button className="relative inline-flex items-center px-2 py-2 h-full focus:z-10 shadow-sm uppercase bg-secondary border-primary hover:bg-secondary-dark text-white focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-10 focus:border-primary focus:text-primary-lighter rounded-sm rounded-l-none">
          <span className="sr-only">Open options</span>
          <ChevronDown className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        {items ? (
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-1 w-56 origin-top-right rounded-md bg-white shadow-md border border-gray-300">
              <div className="">
                {items.map((item) => (
                  <Menu.Item key={item.text}>
                    {({ active }) => (
                      <button
                        onClick={item.onClick}
                        className={classNames(
                          "block px-4 py-2 text-sm w-full text-left",
                          active ? "bg-primary text-white" : "text-primary"
                        )}
                      >
                        {item.text}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        ) : null}
      </Menu>
    </div>
  );
};

export default Button;
