export const DATA_STATUS = Object.freeze({
  INCOMPLETE: -1,
  COMPLETE: 0,
  DRAFT: 1,
  ERROR: 3,
});
export const AUDIT_STATUS = Object.freeze({
  CLOSED: 0,
  OPEN: 1,
});

export const OCCURRENCE = Object.freeze({
  DAILY: "Daily",
  WEEKLY: "Weekly",
});

export const CHART_DATA_TYPE = Object.freeze({
  PRODUCTION: "Production %",
  MORTALITY: "Mortality %",
});

export const CHART_TYPE = Object.freeze({
  BAR: "bar",
  LINE: "line",
  TABLE: "table",
  METRIC: "metric",
  TREND: "trend",
  SCATTER: "scatter",
  NOTE: "note",
});

export const LOADED_STATUS = Object.freeze({
  LOADED: "loaded",
  ERROR: "error",
  LOADING: "loading",
});

export const API_TIMEZONE = "Europe/London";

export const DASHBOARD_DATASOURCES = Object.freeze({
  PRODUCTION: "Production",
  GRADING: "Grading",
  GROUPPRODUCTION: "groupproduction",
});