import { XCircle, CheckCircle, Info, AlertCircle } from "lucide-react";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { cn } from "helpers/common";

interface IAlertProps {
  className?: string;
  theme?: "danger" | "success" | "warning" | "info";
  size?: "sm";
  children: React.ReactNode;
  onClick?: () => void;
}

export default function Alert({
  theme,
  size,
  children,
  className,
  onClick,
  ...rest
}: IAlertProps) {
  return (
    <div
      {...rest}
      onClick={onClick}
      className={cn(
        theme === "danger" && "border-danger-600 bg-white",
        theme === "success" && "border-success-400 bg-white",
        theme === "warning" && "border-warning-400 bg-white",
        theme === "info" && "border-primary bg-white",
        isNullEmptyOrWhitespace(theme) && "border-gray-400 bg-gray-50",
        className,
        size === "sm" ? "p-2" : "p-4",
        "border-l-4"
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <AlertIcon theme={theme} />
        </div>
        <div className="ml-3">
          <div
            className={cn(
              theme === "danger" && "text-danger-600",
              theme === "success" && "text-success-700",
              theme === "warning" && "text-warning-700",
              theme === "info" && "text-primary",
              size === "sm" ? "text-xs" : "text-sm"
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

type AlertIconProps = {
  theme?: "danger" | "success" | "warning" | "info";
};

function AlertIcon({ theme }: AlertIconProps) {
  if (theme === "danger") {
    return <XCircle className="h-5 w-5 text-danger-400" aria-hidden="true" />;
  }

  if (theme === "success") {
    return (
      <CheckCircle className="h-5 w-5 text-success-400" aria-hidden="true" />
    );
  }

  if (theme === "warning") {
    return (
      <AlertCircle className="h-5 w-5 text-warning-400" aria-hidden="true" />
    );
  }

  if (theme === "info") {
    return <Info className="h-5 w-5 text-primary" aria-hidden="true" />;
  }

  return <Info className="h-5 w-5 text-gray-400" aria-hidden="true" />;
}
