import React, { useMemo } from "react";
import {
  BarChart,
  LineChart,
  TableChart,
  MetricChart,
  TrendChart,
  ScatterChart,
  NoteChart,
} from "components/core";
import { formatChartData } from "helpers/dashboardUtilities";
import { Button } from "components/core";
import classNames from "classnames";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";

const chartComponents = {
  bar: BarChart,
  line: LineChart,
  table: TableChart,
  metric: MetricChart,
  trend: TrendChart,
  scatter: ScatterChart,
  note: NoteChart,
};

function generateKey(chart) {
  let result = chart.id;

  if (chart.type === "table") {
    // Rerender table if sort order changes
    const sortOrder = [
      chart?.settings?.sortByKey,
      chart?.settings?.sortByDirection,
    ]
      .filter(Boolean)
      .join("-");

    if (sortOrder) {
      result += `-${sortOrder}`;
    }
  }

  return result;
}

function ButtonExport({ chartType, chartKeys, data, className, children }) {
  function exportFile(type) {
    import("export-from-json").then(({ default: exportFromJSON }) => {
      const exportType =
        type.toLowerCase() === "csv"
          ? exportFromJSON.types.csv
          : exportFromJSON.types.xls;

      const filteredData = [];
      if (["table", "bar"].includes(chartType)) {
        for (const d of data) {
          const { id, tooltip, ...rest } = d; // remove unwanted properties
          const newD = { ...rest };

          for (const [key, value] of Object.entries(newD)) {
            if (!isNullEmptyOrWhitespace(value?.value)) {
              newD[key] = value.value;
            }
          }

          filteredData.push(newD);
        }
      } else if (["line"].includes(chartType)) {
        const xKey = chartKeys[0].id;

        const result = data.reduce((acc, curr) => {
          curr.data.forEach((item) => {
            const { x, y } = item;
            const found = acc.find((a) => a[xKey] === x);
            if (found) {
              found[curr.id] = y;
            } else {
              acc.push({ [xKey]: x, [curr.id]: y });
            }
          });
          return acc;
        }, []);

        result.sort((a, b) => a[xKey] - b[xKey]);

        filteredData.push(...result);
      }

      exportFromJSON({
        data: filteredData,
        fileName: "export-to-excel",
        exportType: exportType,
      });
    });
  }

  return (
    <div
      className={classNames(
        "inline-flex text-sm text-gray-800 cursor-pointer hover:text-primary",
        className
      )}
    >
      <Button
        theme="text"
        title="Click to export"
        options={[
          {
            id: "excel",
            text: "Export to Excel",
            onClick: () => exportFile("excel"),
          },
          {
            id: "csv",
            text: "Export to CSV",
            onClick: () => exportFile("csv"),
          },
        ]}
      >
        {children}
      </Button>
    </div>
  );
}

export default function DynamicChart({
  chart,
  data,
  standardData,
  notes,
  className = "",
  eventFunctions = undefined,
  save,
}) {
  const ChartComponent = chartComponents[chart.type] ?? "div";
  const key = generateKey(chart);

  const chartData = useMemo(() => {
    return formatChartData(chart, data, standardData, notes);
  }, [chart, data, standardData, notes]);

  // let keys = chart.keys;
  // if (chart.type === CHART_TYPE.BAR || chart.type === CHART_TYPE.LINE) {
  //   // Always remove first key as it used for X-Axis
  //   keys = chart.keys.filter((k, i) => {
  //     return i > 0;
  //   });
  // } else if (chart.type === CHART_TYPE.METRIC) {}

  return (
    <>
      {["table", "bar", "line"].includes(chart.type) && (
        <ButtonExport
          chartKeys={chart.keys}
          chartType={chart.type}
          data={chartData.data}
          className="absolute right-4 top-0 z-10"
        >
          <span className="sr-only">Export...</span>
        </ButtonExport>
      )}
      <ChartComponent
        {...chart}
        key={key}
        data={chartData.data}
        standardData={chartData.standardData}
        note={chartData.note}
        keys={chart.keys}
        eventFunctions={eventFunctions}
        save={save}
      />
    </>
  );
}
