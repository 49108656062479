import { IFormData } from "helpers/formUtilities";
import { useCallback, useEffect, useMemo, useState } from "react";
import useFetch from "./useFetch";
import { db } from "db";
import {
  getPrevDay,
  isDotNetDateString,
  isSQLDateString,
  isUKDateString,
  localDateFromSQL,
  localDateFromUnix,
  localDateToSQL,
  sqlDateObjectFromServerTZ,
} from "helpers/dateUtilities";
import { getPWAIDfromFormValuesRecord } from "helpers/formsUtilities";
import { DateTime } from "luxon";
import {
  DBFormDataQueue,
  FORM_DATA_QUEUE_STATUS,
  convertDBFormDataQueueToNetworkFormData,
  serialiseFormData,
} from "db/FormDataQueue";
import { mergeLocalDataWithNetworkData as dbMergeLocalDataWithNetworkData } from "db/FormDataQueue";
import {
  checkIsEpoch,
  checkIsUUID,
  isNullEmptyOrWhitespace,
} from "helpers/common";
import { useLiveQuery } from "dexie-react-hooks";

const defaultData: IFormData[] = [];

/**
 * TODO: cannot currently use as we need to keep the service worker cache up to date for offline use.
 * To make this worked we would need to use a custom indexedDb implementation that can be used instead of the service worker cache.
 */
type useFormDataGetManyByFormTypeProps = {
  enabled?: boolean;
  farmId: string;
  houseId: number;
  formType: string;
  fromDate: string;
  toDate: string;
  moduleId: string;
};

export const useFormDataGetManyByFormType = ({
  enabled = true,
  farmId,
  houseId,
  formType,
  fromDate,
  toDate,
  moduleId,
}: useFormDataGetManyByFormTypeProps) => {
  const { error, execute } = useFetch();
  const [isLoading, setIsLoading] = useState(enabled);
  const [isFetched, setIsFetched] = useState(false);
  const [data, setData] = useState<IFormData[]>([]);

  const fetchDataFromNetworkOrCache = useCallback(
    async (fetchUrl: string) => {
      if (!navigator.onLine) {
        return await fetchFormValuesFromCache(fetchUrl);
      }

      try {
        const response = await execute("GET", fetchUrl);

        return {
          ...response,
          data: response.data?.d,
        };
      } catch (error) {
        console.error(error);
        return await fetchFormValuesFromCache(fetchUrl);
      }
    },
    [execute]
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const fetchUrl = `/api/formvaluesbytype-get?formType=${formType}&farmId=${farmId}&fromDate=${fromDate}&toDate=${toDate}&moduleId=${moduleId}`;
    const response = await fetchDataFromNetworkOrCache(fetchUrl);
    // Not applicable when not using MSAL hook
    // if (response?.error === "request not ready") {
    //   // Don't change fetched or loading states here,
    //   // as we want to keep trying to fetch the data
    //   return defaultData;
    // }

    const formDatas = ((response?.data ?? defaultData) as IFormData[]).filter(
      (fd) => !!houseId && fd.House === houseId
    );

    // Get all localData records between start and end dates.
    const localData = await getLocalPendingAndFailedData({
      formType,
      farmId,
      houseId,
      fromDate,
      toDate,
    });

    // Merge localData with networkData
    const newData = await mergeLocalDataWithNetworkData(
      localData,
      formDatas,
      formType
    );

    setData(newData);
    setIsLoading(false);
    setIsFetched(true);

    return newData;
  }, [
    farmId,
    formType,
    fromDate,
    houseId,
    moduleId,
    toDate,
    fetchDataFromNetworkOrCache,
  ]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    isFetched,
    error,
    data,
  };
};

type useFormDataGetManyByFormNameProps = {
  enabled?: boolean;
  farmId: string;
  houseId: number;
  formId: string;
  formType: string;
  fromDate: string;
  toDate: string;
  moduleId: string;
};

export const useFormDataGetManyByFormId = ({
  enabled = true,
  farmId,
  houseId,
  formId,
  formType,
  fromDate,
  toDate,
  moduleId,
}: useFormDataGetManyByFormNameProps) => {
  const {
    isLoading: isLoadingFormDatas,
    isFetched: isFetchedFormDatas,
    error: errorFormDatas,
    data: formDatas,
  } = useFormDataGetManyByFormType({
    enabled: enabled && !isNullEmptyOrWhitespace(formId),
    farmId,
    houseId,
    formType,
    fromDate,
    toDate,
    moduleId,
  });

  const data = useMemo(() => {
    if (isNullEmptyOrWhitespace(formId)) return defaultData;

    return formDatas.filter(
      (fd) => fd.FormName?.toLowerCase() === formId.toLowerCase()
    );
  }, [formDatas, formId]);

  return {
    isLoading: isLoadingFormDatas,
    isFetched: isFetchedFormDatas,
    error: errorFormDatas,
    data,
  };
};

type useFormDataGetOnePlusPreviousByIdProps = {
  enabled?: boolean;
  farmId: string;
  houseId?: number;
  id: string;
  formId: string;
  formType: string;
  moduleId: string;
};

/**
 * Fetches form data by ID and also fetches the previous record if it exists.
 * The first record in the array is the current record, the second is the previous record.
 */
export const useFormDataGetOnePlusPreviousById = ({
  enabled = true,
  id,
  farmId,
  houseId,
  formId,
  formType,
  moduleId,
}: useFormDataGetOnePlusPreviousByIdProps) => {
  const { error, execute } = useFetch();
  const [isLoading, setIsLoading] = useState(enabled);
  const [isFetched, setIsFetched] = useState(false);
  const [data, setData] = useState<(IFormData | undefined)[]>(defaultData);

  const fetchDataFromCache = useCallback(async () => {
    const fetchUrl = `/api/formvaluesbytype-get?formtype=${formType}&farmid=${farmId}&moduleid=${moduleId}`;
    const response = await fetchFormValuesFromCache(fetchUrl);

    const data = response?.data?.filter(
      (fd) => fd.FormName?.toLowerCase() === formId.toLowerCase()
    );

    return { data: data ?? defaultData, error: response.error };
  }, [farmId, formId, formType, moduleId]);

  const fetchDataFromNetworkOrCache = useCallback(
    async (fetchUrl: string) => {
      if (!navigator.onLine) {
        return await fetchDataFromCache();
      }

      try {
        const response = await execute("GET", fetchUrl);

        return {
          ...response,
          data: response.data?.d,
        };
      } catch (error) {
        console.error(error);
        return await fetchDataFromCache();
      }
    },
    [execute, fetchDataFromCache]
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const fetchUrl = `/api/formvaluesbyid-get?id=${id}&formId=${formId}&formType=${formType}&moduleId=${moduleId}`;
    const response = await fetchDataFromNetworkOrCache(fetchUrl);
    // Not applicable when not using MSAL hook
    // if (response?.error === "request not ready") {
    //   // Don't change fetched or loading states here,
    //   // as we want to keep trying to fetch the data
    //   return defaultData;
    // }

    const formData = (response?.data ?? defaultData) as IFormData[];

    if (isNullEmptyOrWhitespace(formData)) {
      setData(defaultData);
      setIsLoading(false);
      setIsFetched(true);
      return defaultData;
    }

    // Get all localData records between start and end dates.
    const localData = await getLocalPendingAndFailedData({
      formId,
      formType,
      farmId,
      houseId,
    });

    // Merge localData with networkData
    const mergedData = await mergeLocalDataWithNetworkData(
      localData,
      formData,
      formId,
      formType
    );

    // At this point, mergedData could contain only current and pervious records, or
    // the entire set of cached records for the formId and formType.
    const currentRecord = getCurrentRecord(mergedData, id, formType, formId);
    const prevData = getPreviousRecord(currentRecord, mergedData, id, formType);
    const newData = [currentRecord, prevData];

    setData(newData);
    setIsLoading(false);
    setIsFetched(true);

    return mergedData;
  }, [
    farmId,
    fetchDataFromNetworkOrCache,
    formId,
    formType,
    houseId,
    id,
    moduleId,
  ]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    isFetched,
    error,
    data,
  };
};

type useFormDataUpdateOrCreateProps = {
  onSuccess?: onSuccessSchema;
  onError?: onErrorSchema;
};

export type onSuccessSchema = (
  response: any,
  data: any,
  variables?: any
) => void;
export type onErrorSchema = (errMessage: string, variables?: any) => void;

export const useFormDataUpdateOrCreate = ({
  onSuccess,
  onError,
}: useFormDataUpdateOrCreateProps) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (formData: FormData, moduleId: string, variables?: any) => {
      const isOnline = navigator.onLine;

      if (isOnline) {
        /* proceed as normal */
        const { data } = await execute(
          "POST",
          "/api/formvalues2-post",
          formData,
          variables
        );

        return data?.d ?? [];
      } else {
        try {
          // Extract the blob data
          const dataBlob = formData.get("data") as Blob;
          const dataText = await dataBlob.text();
          const dataJson = JSON.parse(dataText);
          const id = dataJson.PWAID;
          const recordId = dataJson.ID;
          const formName = dataJson.FormName;
          const formType = dataJson.FormType;
          const farmCode = dataJson.FarmCode;
          const houseNumber = dataJson.Data.House;
          const dateApplies = localDateFromSQL(
            dataJson.Data.DateApplies
          )?.getTime();
          const lastModified = localDateFromSQL(
            dataJson.Data.LastModified
          )?.getTime();

          if (dateApplies === undefined || lastModified === undefined) {
            throw new Error("DateApplies or LastModified is undefined");
          }

          const serialisedFormData = await serialiseFormData(formData);

          await db.formdataqueue.put({
            id,
            recordId,
            formName,
            formType,
            farmCode,
            moduleId,
            houseNumber,
            dateApplies,
            lastModified,
            sendAttempts: 0,
            sendStatus: FORM_DATA_QUEUE_STATUS.PENDING,
            data: serialisedFormData,
          });

          onError?.(
            "You are currently offline, your submission will be synced when you are back online.",
            variables
          );

          return dataJson;
        } catch (error) {
          console.error(error);
          // TODO: consider adding a retry mechanism here, and/or an external log of failed requests.
          onError?.(
            "An error occurred while trying to save your offline submission. Please try again. If the problem persists, please contact support.",
            variables
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [execute]
  );

  return { isLoading, error, mutate };
};

export const useSyncFormDataGetMany = ({
  excludedStatus,
}: {
  excludedStatus?: FORM_DATA_QUEUE_STATUS[];
} = {}) => {
  const queue = useLiveQuery(() => {
    if (!isNullEmptyOrWhitespace(excludedStatus)) {
      return db.formdataqueue
        .where("sendStatus")
        .noneOf(excludedStatus)
        .toArray();
    }

    return db.formdataqueue.toArray();
  });

  return { data: queue };
};

async function getLocalPendingAndFailedData({
  formId,
  formType,
  farmId,
  houseId,
  fromDate,
  toDate,
}: {
  formId?: string;
  formType: string;
  farmId: string;
  houseId?: number;
  fromDate?: string;
  toDate?: string;
}) {
  //prettier-ignore
  let result = await db.formdataqueue
    .where("sendStatus")
    .equals(FORM_DATA_QUEUE_STATUS.PENDING)
    .or("sendStatus")
    .equals(FORM_DATA_QUEUE_STATUS.FAILED)
    .and((fd: DBFormDataQueue) => fd.formType.toLowerCase() === formType.toLowerCase())
    .and((fd: DBFormDataQueue) => fd.farmCode.toLowerCase() === farmId.toLowerCase())
    .and((fd: DBFormDataQueue) => {
      if (isNullEmptyOrWhitespace(formId)) {
        return true;
      }

      return fd.formName.toLowerCase() === formId.toLowerCase()
    })
    .and((fd: DBFormDataQueue) => {
      if (isNullEmptyOrWhitespace(houseId)) {
        return true;
      }

      return fd.houseNumber.toString() === houseId.toString()
    })
    .and(
      (fd: DBFormDataQueue) => {
        if (isNullEmptyOrWhitespace(fromDate) || isNullEmptyOrWhitespace(toDate)) {
          return true;
        }

        return (
          fd.dateApplies >= DateTime.fromSQL(fromDate).toMillis() && 
          fd.dateApplies <= DateTime.fromSQL(toDate).toMillis()
        );
      }
    )
    .toArray();

  // Sort to ensure localDate is in chronological descending order
  result.sort((a, b) => b.lastModified - a.lastModified);

  return result;
}

async function mergeLocalDataWithNetworkData(
  localData: DBFormDataQueue[],
  formData: IFormData[],
  formType: string,
  formId?: string
) {
  const newFormData = await Promise.all(
    formData.map(async (fd) => {
      // Build an array of indices of all entries that match networkEntity in localData
      let localEntityIndices: any[] = [];
      const dateApplies = localDateFromSQL(fd.DateApplies)?.getTime();
      const networkPWAID = getPWAIDfromFormValuesRecord(fd);

      localData.forEach((ld, index) => {
        if (
          ((!isNullEmptyOrWhitespace(ld.id) &&
            !isNullEmptyOrWhitespace(networkPWAID) &&
            ld.id === networkPWAID) ||
            ld.recordId?.toString() === fd.ID?.toString()) &&
          ld.formName.toLowerCase() === fd.FormName.toLowerCase() &&
          ld.formType.toLowerCase() === fd.FormType.toLowerCase() &&
          ld.farmCode.toLowerCase() === fd.FarmCode.toLowerCase() &&
          ld.houseNumber.toString() === fd.House.toString() &&
          ld.dateApplies === dateApplies
        ) {
          localEntityIndices.push(index);
        }

        // TODO: ideally the network would return the PWAID, but it doesn't, so we have to find it ourselves
        // ...if we are able to make this happen in the future, we can use the following code instead of the above
        // if (ld.id === networkPWAID) {
        //   localEntityIndices.push(index);
        // }
      });

      if (localEntityIndices.length) {
        // Find matching local entity in list
        const matchingLocalEntity = localData[localEntityIndices[0]];
        const localLastModifiedEpoch = matchingLocalEntity.lastModified;
        const networkLastModifiedEpoch = sqlDateObjectFromServerTZ(
          fd.LastModified
        ).localised.getTime();
        if (localLastModifiedEpoch > networkLastModifiedEpoch) {
          // Latest matching entity
          return await dbMergeLocalDataWithNetworkData(
            localData,
            localEntityIndices,
            fd,
            matchingLocalEntity
          );
        }
      }

      // Lastly
      for (var i = localEntityIndices.length - 1; i >= 0; i--) {
        // Delete local entity from local DB
        db.formdataqueue.delete(localData[localEntityIndices[i]].id);
        // Remove stale matched entites from memory
        localData.splice(localEntityIndices[i], 1);
      }

      return fd;
    })
  );

  if (localData.length) {
    // Push remaining local entities into networkData that don't exist on network
    const remainingLocalData = await Promise.all(
      localData
        .filter(
          (ld) =>
            (isNullEmptyOrWhitespace(formId) ||
              ld.formName.toLowerCase() === formId.toLowerCase()) &&
            ld.formType.toLowerCase() === formType.toLowerCase()
        )
        .map(async (ld) => {
          const localFormData = await convertDBFormDataQueueToNetworkFormData(
            ld
          );

          var formData = localFormData.Data as IFormData;
          formData._SendStatus = ld.sendStatus; // Add send status for display purposes only

          return formData;
        })
    );

    newFormData.push(...remainingLocalData);
  }

  // Change all server datetimes to local date object, making comparison easier going forward
  const localisedNetworkData = newFormData.map((fd) => {
    return {
      ...fd,
      _DateApplies: sqlDateObjectFromServerTZ(fd.DateApplies),
      _LastModified:
        fd.LastModified !== "0001-01-01 00:00:00"
          ? sqlDateObjectFromServerTZ(fd.LastModified)
          : null,
    } as IFormData;
  });

  return localisedNetworkData;
}

export async function fetchFormValuesFromCache(fetchUrl: string) {
  try {
    fetchUrl = fetchUrl.toLowerCase();

    const sameOrigin = window.location.origin;

    // if the fetchUrl is a relative URL, prepend the same origin
    if (fetchUrl.startsWith("/")) {
      fetchUrl = `${sameOrigin}${fetchUrl}`;
    }

    // Try to fetch the data from the cache.
    const cache = await caches.open("api");

    // Remove `fromDate` and `toDate` from the fetchUrl
    const url = new URL(fetchUrl);
    if (url.searchParams.has("fromdate")) {
      url.searchParams.delete("fromdate");
    }
    if (url.searchParams.has("todate")) {
      url.searchParams.delete("todate");
    }
    fetchUrl = url.toString();

    // cache url is `https://{subdomain}.poultrycloud.com/api/formvaluesbytype-get?formType={formType}&farmId={farmId}&&moduleId={moduleId}``
    const cachedResponse = await cache.match(fetchUrl);

    if (cachedResponse && cachedResponse.ok) {
      // If we have a cached response
      const responseBody = (await cachedResponse.json()) as { d: IFormData[] };

      return { data: responseBody.d };
    } else {
      // If there's no cached response, fetch from the network.
      return { data: [] as IFormData[] };
    }
  } catch (error) {
    const errMessage = `Failed to fetch form values from cache for URL: ${fetchUrl}`;
    console.error(errMessage, error);
    return { data: [] as IFormData[], error: errMessage };
  }
}

export async function tryInvalidateFormDataCache(fetchUrl: string) {
  try {
    if (!navigator.onLine) return; // we don't want to invalidate cache when offline

    fetchUrl = fetchUrl.toLowerCase();

    // Remove `fromDate` and `toDate` from the fetchUrl
    const url = new URL(fetchUrl);
    if (url.searchParams.has("fromDate")) {
      url.searchParams.delete("fromDate");
    }
    if (url.searchParams.has("toDate")) {
      url.searchParams.delete("toDate");
    }
    fetchUrl = url.toString();

    const cache = await caches.open("api");
    const deleteResult = await cache.delete(fetchUrl);
    console.log("on invalidate cache", fetchUrl);
    if (!deleteResult) {
      console.log(
        "Cache entry not found or deletion failed for URL:",
        fetchUrl
      );
    }
  } catch (error) {
    console.error("Failed to invalidate cache for URL:", fetchUrl, error);
  }
}

// export async function insertSampleFormValuesToCache() {
//   const data = {
//     d: [
//       {
//         __type: "PWAClassLibrary.PWAForms+PWAFieldValues",
//         ID: "4452",
//         FormName: "farmsupportchecklist3",
//         FormType: "audit",
//         FarmCode: "104",
//         ParentPWAID: "",
//         DateApplies: "2023-10-23 00:00:00.000",
//         LastModified: "2023-10-23 14:57:19.644",
//         Status: 0,
//         AuditStatus: 0,
//         House: 1,
//         CreatedBy: "Zach Hill",
//         PenValues: [
//           {
//             Pen: "1",
//             BirdsAlive: {
//               BirdsAlive: null,
//               FemaleAlive: null,
//               MaleAlive: null,
//             },
//             Values: [
//               {
//                 ID: "4452",
//                 Ref: "1",
//                 Value: "1",
//                 QuestionGroup: "1",
//                 Score: 0,
//                 Text: "Pass",
//                 Owner: "0",
//                 Days: null,
//                 RepeaterID: null,
//               },
//               {
//                 ID: "4452",
//                 Ref: "10",
//                 Value: "5",
//                 QuestionGroup: "10",
//                 Score: 0,
//                 Text: null,
//                 Owner: "0",
//                 Days: null,
//                 RepeaterID: null,
//               },
//               {
//                 ID: "4452",
//                 Ref: "11",
//                 Value: "6",
//                 QuestionGroup: "11",
//                 Score: 0,
//                 Text: null,
//                 Owner: "0",
//                 Days: null,
//                 RepeaterID: null,
//               },
//               {
//                 ID: "4452",
//                 Ref: "12",
//                 Value: "1",
//                 QuestionGroup: "12",
//                 Score: 0,
//                 Text: "Pass",
//                 Owner: "0",
//                 Days: null,
//                 RepeaterID: null,
//               },
//               {
//                 ID: "4452",
//                 Ref: "13",
//                 Value: "1",
//                 QuestionGroup: "13",
//                 Score: 0,
//                 Text: "Pass",
//                 Owner: "0",
//                 Days: null,
//                 RepeaterID: null,
//               },
//             ],
//           },
//         ],
//       },
//       {
//         __type: "PWAClassLibrary.PWAForms+PWAFieldValues",
//         ID: "4691",
//         FormName: "farmsupportchecklist3",
//         FormType: "audit",
//         FarmCode: "104",
//         ParentPWAID: "",
//         DateApplies: "2023-11-22 00:00:00.000",
//         LastModified: "2023-11-22 18:11:30.611",
//         Status: 0,
//         AuditStatus: 0,
//         House: 1,
//         CreatedBy: "Zach Hill",
//         PenValues: [
//           {
//             Pen: "1",
//             BirdsAlive: {
//               BirdsAlive: null,
//               FemaleAlive: null,
//               MaleAlive: null,
//             },
//             Values: [
//               {
//                 ID: "4691",
//                 Ref: "1",
//                 Value: "1",
//                 QuestionGroup: "1",
//                 Score: 0,
//                 Text: "Pass",
//                 Owner: "0",
//                 Days: null,
//                 RepeaterID: null,
//               },
//               {
//                 ID: "4691",
//                 Ref: "10",
//                 Value: "N/a",
//                 QuestionGroup: "10",
//                 Score: 0,
//                 Text: null,
//                 Owner: "0",
//                 Days: null,
//                 RepeaterID: null,
//               },
//               {
//                 ID: "4691",
//                 Ref: "11",
//                 Value: "2",
//                 QuestionGroup: "11",
//                 Score: 0,
//                 Text: null,
//                 Owner: "0",
//                 Days: null,
//                 RepeaterID: null,
//               },
//               {
//                 ID: "4691",
//                 Ref: "12",
//                 Value: "1",
//                 QuestionGroup: "12",
//                 Score: 0,
//                 Text: "Pass",
//                 Owner: "0",
//                 Days: null,
//                 RepeaterID: null,
//               },
//               {
//                 ID: "4691",
//                 Ref: "13",
//                 Value: "1",
//                 QuestionGroup: "13",
//                 Score: 0,
//                 Text: "Pass",
//                 Owner: "0",
//                 Days: null,
//                 RepeaterID: null,
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   };

//   try {
//     const cache = await caches.open("api");
//     const fetchUrl = "http://localhost:3000/api/formvaluesbytype-get?formtype=audit&farmid=104&moduleid=audit";

//     await cache.put(fetchUrl, new Response(JSON.stringify(data)));
//   } catch (error) {
//     console.error("Failed to insert sample form values to cache", error);
//   }
// }

export function getCurrentRecord(
  formDatas: IFormData[] | undefined,
  id: string | undefined,
  formType: string | undefined,
  formId: string | undefined
) {
  if (
    isNullEmptyOrWhitespace(formDatas) ||
    isNullEmptyOrWhitespace(id) ||
    isNullEmptyOrWhitespace(formType) ||
    isNullEmptyOrWhitespace(formId)
  )
    return undefined;

  // console.log("getCurrentRecord:", formDatas, id, formType, formId)

  const data = formDatas.find((fd) => {
    if (isNullEmptyOrWhitespace(id)) return false;

    if (
      formType?.toLowerCase() === "production" &&
      ["weeklyproduction", "production", "monthlyproduction"].includes(
        formId.toLowerCase()
      ) &&
      checkIsEpoch(id)
    ) {
      // by date (epoch)
      return fd._DateApplies?.normalised?.getTime() === Number(id);
    }

    if (!checkIsUUID(id)) {
      // by record ID
      return fd.ID?.toString() === id;
    }

    if (formId?.toLowerCase().startsWith("ncn_")) {
      // by parentPWAID
      return fd.ParentPWAID?.toString() === id;
    }

    // by PWAID
    return getPWAIDfromFormValuesRecord(fd) === id;
  });

  // convert weird dot net string to sql date string
  // @deprecated don't remove until we're sure we don't need it
  if (data) {
    data.PenValues?.forEach((pv) => {
      pv.Values?.forEach((v) => {
        // Format values such as Dates and Numbers
        // Convert dates
        if (
          isSQLDateString(v.Value) ||
          isDotNetDateString(v.Value) ||
          isUKDateString(v.Value)
        ) {
          const convertedValue = sqlDateObjectFromServerTZ(v.Value);
          const newValue =
            localDateToSQL(convertedValue.normalised, {
              includeOffset: false,
            }) ?? "";

          v.Value = newValue;
        }
      });
    });
  }

  return data;
}

export const getPreviousRecord = (
  currentRecord: IFormData | undefined,
  formDatas: IFormData[] | undefined,
  id: string | undefined,
  formType: string | undefined
) => {
  if (
    isNullEmptyOrWhitespace(formDatas) ||
    isNullEmptyOrWhitespace(id) ||
    isNullEmptyOrWhitespace(formType)
  )
    return undefined;

  if (formType.toLowerCase() === "production") {
    // Find by date (epoch)
    const timestamp = Number(id);
    const prevDay = getPrevDay(localDateFromUnix(timestamp));

    const data = formDatas?.find(
      (fd) => fd._DateApplies?.normalised?.getTime() === prevDay.getTime()
    );

    // convert weird dot net string to sql date string
    // @deprecated don't remove until we're sure we don't need it
    if (data) {
      data.PenValues?.forEach((pv) => {
        pv.Values?.forEach((v) => {
          // Format values such as Dates and Numbers
          // Convert dates
          if (
            isSQLDateString(v.Value) ||
            isDotNetDateString(v.Value) ||
            isUKDateString(v.Value)
          ) {
            const convertedValue = sqlDateObjectFromServerTZ(v.Value);
            const newValue =
              localDateToSQL(convertedValue.normalised, {
                includeOffset: false,
              }) ?? "";

            v.Value = newValue;
          }
        });
      });
    }

    return data;
  }

  if (currentRecord) {
    // Find by index of current record
    // ...for this to work the formDatas results must be in DateApplies ASC order
    const reorderedData = formDatas.sort(
      (a, b) =>
        a._DateApplies?.normalised?.getTime() -
        b._DateApplies?.normalised?.getTime()
    );
    const formDataIndex = reorderedData.indexOf(currentRecord);
    const prevFormDataIndex = formDataIndex > 0 ? formDataIndex - 1 : undefined;

    const prevFormData =
      prevFormDataIndex !== undefined
        ? reorderedData[prevFormDataIndex]
        : undefined;

    // console.log(formData._DateApplies?.normalised, prevFormData?._DateApplies?.normalised);

    if (
      prevFormData?._DateApplies?.normalised &&
      prevFormData._DateApplies.normalised.getTime() >=
        currentRecord?._DateApplies?.normalised?.getTime()
    ) {
      console.error(
        "Previous form data date is greater than or equal to current form data date. DateApplies must be in ASC order."
      );
    }

    // convert weird dot net string to sql date string
    // @deprecated don't remove until we're sure we don't need it
    if (prevFormData) {
      prevFormData.PenValues?.forEach((pv) => {
        pv.Values?.forEach((v) => {
          // Format values such as Dates and Numbers
          // Convert dates
          if (
            isSQLDateString(v.Value) ||
            isDotNetDateString(v.Value) ||
            isUKDateString(v.Value)
          ) {
            const convertedValue = sqlDateObjectFromServerTZ(v.Value);
            const newValue =
              localDateToSQL(convertedValue.normalised, {
                includeOffset: false,
              }) ?? "";

            v.Value = newValue;
          }
        });
      });
    }

    return prevFormData;
  }

  // likely a new form, just return latest record
  return formDatas?.[0];
};
