import { IMenuItem } from "components/Sidebar";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useMenuGetOne } from "hooks/useMenu";
import { createContext, useMemo } from "react";
import { useUser } from "hooks/useUser";

export interface IMenusContext {
  menus: IMenuItem[];
}

const MenusContext = createContext({} as IMenusContext);

const MenuProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, isSignedIn } = useUser();

  const { menuItems } = useMenuGetOne({
    enabled: isSignedIn && !isNullEmptyOrWhitespace(user),
    user,
  });

  const contextValue = useMemo(() => ({ menus: menuItems }), [menuItems]);
  
  return (
    <MenusContext.Provider value={contextValue}>
      {children}
    </MenusContext.Provider>
  )
}

export { MenuProvider as default, MenusContext };