import useFetch from "./useFetch";
import { useCallback, useContext, useEffect, useState } from "react";
import { type IUser } from "helpers/formUtilities";
import { IMenuItem } from "components/Sidebar";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { isSuperUser } from "./useUser";
import { MenusContext } from "context/MenuProvider";

export const useMenus = () => {
  const context = useContext(MenusContext);

  if (!context) {
    throw new Error("useMenus must be used within a AppDataProvider");
  }

  return context;
};


type useMenuGetOneProps = {
  enabled?: boolean;
  user: IUser | undefined;
};

const defaultMenuItems: IMenuItem[] = [];

export const useMenuGetOne = ({ enabled = true, user }: useMenuGetOneProps) => {
  const { isLoading, error, execute } = useFetch();

  const [menuItems, setMenuItems] = useState<IMenuItem[]>(defaultMenuItems);

  const fetchData = useCallback(async () => {
    if (isNullEmptyOrWhitespace(user)) {
      console.error("user is null, empty, or whitespace");
      return;
    }

    const { data } = await execute("GET", "/api/menus-get");

    const menuItems = (data?.d ?? defaultMenuItems) as IMenuItem[];

    const superUserOnlyViews = ["dashboardbuilder"];

    let menuMap = new Map();
    for (const item of menuItems) {
      if (superUserOnlyViews.includes(item.View) && !isSuperUser(user)) {
        // retrict access to super user only pages
        continue;
      }

      if (item.Level === "1") {
        // For every child menu, add it to the parent menu
        if (!menuMap.has(item.ParentID)) {
          // Parent doesn't exist, create it
          const parent = menuItems.find(
            (m: { ID: any }) => m.ID === item.ParentID
          );
          menuMap.set(item.ParentID, { ...parent, Children: [] });
        }

        menuMap.get(item.ParentID).Children.push(item);
      }
    }
    
    const newMenuItems = Array.from(menuMap.values()) ?? defaultMenuItems;

    setMenuItems(newMenuItems);

    return newMenuItems;
  }, [execute, user]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    error,
    menuItems,
  };
};
