import { DATA_STATUS } from "constants.js";
import { FORM_DATA_QUEUE_STATUS } from "db/FormDataQueue";
import { dateDiffInDays } from "helpers/dateUtilities";

export default function ListItemStatus({
  dataStatus,
  networkStatus,
  date,
  dateToday,
  loaded = true,
}) {
  if (!loaded) {
    return <div className="animate-pulse h-2 w-20 bg-gray-300 rounded"></div>;
  }

  if (networkStatus === FORM_DATA_QUEUE_STATUS.PENDING) {
    return <span className="text-warning-500">Pending</span>;
  } else if (networkStatus === FORM_DATA_QUEUE_STATUS.FAILED) {
    return <span className="text-danger-500">Failed</span>;
  } else if (dataStatus === DATA_STATUS.COMPLETE) {
    return <span className="text-success-500">Complete</span>;
  } else if (dateDiffInDays(date, dateToday) >= 1) {
    return <span className="text-danger-500">Overdue</span>;
  } else {
    return <span className="text-gray-500">Incomplete</span>;
  }
}
