import React, { useState } from "react";
import FormButtons from "components/forms/FormButtons";
import useSticky from "hooks/useSticky";
import classNames from "classnames";

export default function Form({
  onFormSubmit,
  onClickCancel,
  children,
  dataStatus,
  showDraft,
  requiredFieldCount,
  requiredFieldCompletedCount,
  button,
  className = "",
  ...other
}) {
  const { targetRef, isSticky } = useSticky({ inverted: true });

  const [isProcessingButtonVisible, setIsProcessingButtonVisible] =
    useState(false);

  //#region Callbacks

  //#endregion

  //#region Event handlers

  /**
   * Handle form submit
   */
  const handleFormSubmit = (ev) => {
    ev.preventDefault();

    setIsProcessingButtonVisible(true);

    // in X seconds, if the callback is not called, then hide the processing button
    setTimeout(() => {
      setIsProcessingButtonVisible(false);
    }, 10000);

    onFormSubmit?.(ev);
  };

  /**
   * Handle click cancel button
   */
  const handleClickCancel = (ev, { ...args } = {}) => {
    if (onClickCancel) {
      onClickCancel(ev, { ...args });
      return;
    }
  };

  //#endregion

  return (
    <form {...other} className={classNames("flex flex-col flex-grow", className)} onSubmit={handleFormSubmit}>
      {children}
      <FormButtons
        _ref={targetRef}
        key="formButtons"
        size={isSticky ? "small" : undefined}
        className={
          isSticky
            ? "fixed bottom-0 left-0 right-0 bg-gray-100 z-10 shadow border-t border-gray-200 py-3 tablet:px-6 desktop:px-8"
            : ""
        }
        isProcessingButtonVisible={isProcessingButtonVisible}
        handleClickCancel={handleClickCancel}
        dataStatus={dataStatus}
        showDraft={showDraft}
        requiredFieldCount={requiredFieldCount}
        requiredFieldCompletedCount={requiredFieldCompletedCount}
        button={button}
      />
    </form>
  );
}
