import React, { useCallback, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { Label } from "components/core";
import Addon from "./Addon";
import classNames from "classnames";
import useCalcTrigger from "hooks/useCalcTrigger";
import { isNullEmptyOrWhitespace } from "helpers/common";

const Text = ({
  label,
  id,
  value,
  defaultValue,
  setValue,
  isFullWidth = false,
  disabled = false,
  labelPosition = "top",
  addonLeft,
  addonRight,
  className = "",
  type,
  render,
  labelSize,
  disableCalcTrigger,
  ...other
}) => {
  useCalcTrigger(value, setValue, disableCalcTrigger); // To act like the input onChange event

  const hasDefaultValueRef = useRef(false);

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback(
    (value) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof value === "undefined" || value === null) return;

      setValue(value);
    },
    [setValue]
  );

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (!defaultValue || !setInputValue || hasDefaultValueRef.current) return;

    if (isNullEmptyOrWhitespace(value)) {
      setInputValue(defaultValue);
      hasDefaultValueRef.current = true;
    }
  }, [value, defaultValue, setInputValue]);

  const classes = ["px-4 py-0"];

  // Addons
  if (addonLeft) {
    classes.push("pl-10");
  }
  if (addonRight) {
    classes.push("pr-10");
  }

  if (type === "hidden") {
    return null;
  }

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div
      className={`${className} ${
        labelPosition === "left" ? "grid grid-cols-2 gap-4" : "relative"
      }`}
    >
      {label && (
        <Label
          id={id}
          data-cy="text-label"
          text={label}
          position={labelPosition}
          addonPosition={addonLeft ? "left" : "right"}
          size={labelSize}
        />
      )}
      <div className={`mt-1`}>
        <div className={`relative ${labelPosition === "inset" ? "py-3" : ""}`}>
          {addonLeft && <Addon position="left">{addonLeft}</Addon>}
          <div
            id={id}
            data-cy="text"
            className={classNames(
              "block w-full tablet:text-sm",
              labelPosition === "left" ? "px-4 py-0" : ""
            )}
          >
            {value}
          </div>
          {addonRight && <Addon position="right">{addonRight}</Addon>}
        </div>
      </div>
    </div>
  );
};

Text.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  labelPosition: PropTypes.string,
  labelSize: PropTypes.oneOf(["large", "small", undefined]),
  addonLeft: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  addonRight: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { Text };
