import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { dateAdd, dateToString, startOfFromDate } from "helpers/dateUtilities";
import { ListItem } from "components/core";
import ListItemIcon from "components/Schedule/ListItemIcon";
import ListItemStatus from "components/Schedule/ListItemStatus";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useActiveMenu } from "hooks/useActiveMenu";

export default function ListItemEvent({
  todayEntries,
  event,
  date,
  dateToday,
  dateHatched,
  formId,
  className,
  ...other
}) {
  const navigate = useNavigate();
  const { activeMenu } = useActiveMenu();

  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [entry, setEntry] = useState(undefined);

  //#region side-effects

  /**
   * Set entry
   */
  useDeepCompareEffect(() => {
    if (
      isNull(todayEntries) ||
      isNullEmptyOrWhitespace(event?.FarmScheduleID) ||
      isNullEmptyOrWhitespace(formId)
    ) {
      setEntry(undefined);

      return;
    }

    const _newEntry = todayEntries.find(
      (e) =>
        e.FormName.toLowerCase() === formId &&
        e.ID?.toString() === event.FarmScheduleID?.toString()
    );
    setEntry(_newEntry);
  }, [todayEntries, event?.FarmScheduleID, formId]);

  // Set To & From date
  useEffect(() => {
    if (!dateHatched || !event) return;

    const { StartDays, EndDays } = event;
    // Start date
    const newFromDate = dateAdd(dateHatched, StartDays, "days");
    setFromDate(newFromDate);
    // End date
    const newToDate = dateAdd(dateHatched, EndDays, "days");
    setToDate(newToDate);
  }, [dateHatched, event]);

  //#endregion

  //#region Callbacks

  /**
   * Handle list item click
   */
  const handleClick = () => {
    const recordId = event?.FarmScheduleID;

    return navigate(
      buildFormPageUrl(activeMenu, formId, null, null, recordId)
    );
  };

  //#endregion

  /**
   * Chris says, this will work!!
   * @author Chris (Kermit)
   * @returns
   */
  const shouldShow = () => {
    const completedDate = event?._CompletedDate;
    // Completed event
    if (completedDate && completedDate.normalised.getTime() === date?.getTime())
      return true;

    // Event list date is the past && toDate is valid in future, don't render
    if (
      !completedDate &&
      date?.getTime() < startOfFromDate(dateToday, "day").getTime() &&
      toDate?.getTime() > date?.getTime()
    )
      return false;

    // Event list date is between from & to date, render
    if (
      !completedDate &&
      (date?.getTime() >= fromDate?.getTime() ||
        date?.getTime() <= toDate?.getTime())
    )
      return true;

    return false;
  };

  return shouldShow() ? (
    <ListItem
      aria-label="event"
      onClick={() => handleClick()}
      {...other}
    >
      <ListItemIcon
        dataStatus={entry?.Status}
        networkStatus={entry?._SendStatus}
        date={date}
        dateToday={dateToday}
      />
      <div className="flex-grow">
        <div className="font-medium">{event.Description}</div>
        <div className="text-sm text-gray-900" data-cy="date-range">
          {fromDate && `${dateToString(fromDate, { includeWeekday: true })} -`}{" "}
          {toDate && `${dateToString(toDate, { includeWeekday: true })}`}
        </div>
        <div className="flex text-xs uppercase">
          <ListItemStatus
            dataStatus={entry?.Status}
            networkStatus={entry?._SendStatus}
            date={date}
            dateToday={dateToday}
          />
        </div>
        {entry && entry._LastModified && (
          <div className="text-xs text-gray-400">
            Updated:{" "}
            {dateToString(entry._LastModified.localised, {
              includeTime: true,
            })}
          </div>
        )}
      </div>
    </ListItem>
  ) : null;
}
