import { Alert, Button, ButtonClicked } from "components/core";
import FormField from "components/forms/FormField";
import { DATA_STATUS } from "constants.js";
import { buildFormValues } from "helpers/formsUtilities";
import {
  getFormDataStatus,
  getPenDataFromFormData,
  IForm,
  IFormField,
  IFormValue,
  IFormValueDataSources,
  IPenFormValid,
} from "helpers/formUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useQuery from "hooks/useQuery";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useFormGetMany } from "hooks/useForm";
import useNetworkStatus from "hooks/useNetworkStatus";
import { toast } from "react-toastify";
import { usePersistantFormValues } from "context/PersistantFormValuesProvider";

interface DashboardParamsProps {
  dashboard: any;
  onSubmit: (params: { [key: string]: any }) => Promise<void>;
  wrapper: any;
}

export default function DashboardParams({
  dashboard,
  onSubmit,
  wrapper,
}: DashboardParamsProps) {
  const { isOnline } = useNetworkStatus();
  const { data: forms } = useFormGetMany();
  const { getPersistantFormValue, setPersistantFormValue } =
    usePersistantFormValues();

  const [formValid, setFormValid] = useState<IPenFormValid[]>([]);
  const [formValues, setFormValues] = useState<IDashboardFormData | undefined>(
    undefined
  );
  const [isProcessingButtonVisible, setIsProcessingButtonVisible] =
    useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  
  const hasSubmitted = useRef<Boolean>(false);

  const query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const dashboardDataSources = dashboard?.dataSources;
  const selectedForm = useMemo<IForm | undefined>(
    () => {
      setFormValid([]);
      hasSubmitted.current = false;

      return forms?.find(
        (f: IForm) =>
          f.FormName?.toLowerCase() === dashboardDataSources?.toLowerCase()
      )
    },
    [forms, dashboardDataSources]
  );

  const dataStatus = useMemo<Number | undefined>(
    () => getFormDataStatus(formValid),
    [formValid]
  );

  useEffect(() => {
    // Load reduced form value from local storage
    const reducedFormData = getPersistantFormValue(`dashboard:${dashboard.id}`);

    if (reducedFormData) {
      const newFormValues = buildFormDataFromKeyValueArray(
        reducedFormData
      );

      // merge new form values with previous form value
      setFormValues((prevState) => {
        return {
          ...prevState,
          ...newFormValues,
        };
      });
    }

    setIsLoading(false);
  }, [dashboard.id, getPersistantFormValue]);

  /**
   * Trigger onSubmit only once on mount
   */
  useEffect(() => {
    if (!hasSubmitted.current && dataStatus === DATA_STATUS.COMPLETE) {
      let _params: { [key: string]: any } = {};
      const reducedFormData = reduceFormDataToKeyValueArray(formValues as any);
      for (const [key, item] of Object.entries(reducedFormData)) {
        _params[key] = item;
      }

      if (Object.keys(_params).length > 0) {
        onSubmit(_params);

        // Save reduced form data to local storage
        setPersistantFormValue(
          `dashboard:${dashboard.id}`,
          reducedFormData
        );

        hasSubmitted.current = true;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, dataStatus]);

  const handleParamsChange = (
    field: IFormField,
    penId: string,
    formValue: IFormValue
  ) => {
    if (!field?.Ref || !penId)
      return new Error("'ref' and 'pen' are required properties.");

    setFormValues((prevState) => {
      const dataSources: IFormValueDataSources = {
        this: prevState ?? {},
        _custom: {
          farmId: farmId,
          houseId: houseId,
        },
        _field: field,
      };

      try {
        const newState = buildFormValues(
          field,
          penId,
          formValue,
          selectedForm,
          dataSources,
          undefined,
          undefined,
          0,
          undefined
        );

        return newState;
      } catch (error) {
        console.error(error);
      }

      return prevState as any;
    });
  };

  const handleParamsValidate = (
    fieldId: string, // We use the id here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
    field: IFormField,
    penNumber: string,
    valid: boolean,
    complete: boolean,
    required: boolean
  ) => {
    setFormValid((prevState) => {
      const newFormValid = prevState.filter(
        (fv) =>
          !(
            fv.Ref === fieldId &&
            (isNullEmptyOrWhitespace(field.QuestionGroup) ||
              fv.QuestionGroup === field.QuestionGroup) &&
            fv.Pen.toString() === penNumber.toString()
          )
      );
      newFormValid.push({
        Ref: fieldId,
        QuestionGroup: field.QuestionGroup,
        Pen: penNumber,
        Valid: valid,
        Complete: complete,
        Required: required,
      });

      return newFormValid;
    });
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (dataStatus !== DATA_STATUS.COMPLETE) {
      toast.error("Form is not complete. Please fill out all required fields");

      return;
    }

    setIsProcessingButtonVisible(true);

    let _params: { [key: string]: any } = {};
    const reducedFormData = reduceFormDataToKeyValueArray(formValues as any);
    for (const [key, item] of Object.entries(reducedFormData)) {
      _params[key] = item;
    }

    // Save reduced form data to local storage
    setPersistantFormValue(
      `dashboard:${dashboard.id}`,
      reducedFormData
    );

    await onSubmit(_params);

    setIsProcessingButtonVisible(false);
  };

  if (!isOnline) {
    return (
      <Alert theme="warning" className="mb-6">
        You are offline. Please connect to the internet to enable filtered
        dashboards.
      </Alert>
    );
  }

  if (isLoading) {
   return null;
  }

  const Wrapper = !!selectedForm ? wrapper : Fragment;

  return (
    <Wrapper>
      {selectedForm ? (
        <>
          {selectedForm.FormFields.map((ff) => (
            <div>
              <FormField
                key={ff.Ref}
                {...{
                  id: ff.Ref,
                  field: ff,
                  penNumber: "1",
                  setFieldValue: handleParamsChange,
                  setFieldValid: handleParamsValidate,
                  formValues: getPenDataFromFormData("1", formValues as any),
                }}
              />
            </div>
          ))}

          {isProcessingButtonVisible ? (
            <ButtonClicked />
          ) : (
            <Button onClick={handleSubmit}>
              Apply Filter
            </Button>
          )}
        </>
      ) : null}
    </Wrapper>
  );
}

interface IDashboardFormData {
  PenValues: [
    {
      Pen: number;
      Values: IDashboardFormValue[];
    }
  ];
}

type IDashboardFormValue = {
  Ref: string;
  Value: string | number;
}

function reduceFormDataToKeyValueArray(formData: IDashboardFormData) {
  const result: { [key: string]: string } = {};
  if (isNullEmptyOrWhitespace(formData?.PenValues)) {
    return result;
  }

  for (const penValue of formData.PenValues) {
    for (const formValue of penValue.Values) {
      result[formValue.Ref] = formValue.Value.toString();
    }
  }

  return result;
}

function buildFormDataFromKeyValueArray(
  formData: { [key: string]: string },
) {
  const values: IDashboardFormValue[] = [];

  for (const [key, value] of Object.entries(formData)) {
    values.push({
      Ref: key,
      Value: value,
    });
  }

  const result: IDashboardFormData = {
    PenValues: [
      {
        Pen: 1,
        Values: values,
      },
    ],
  };

  return result;
}