import { Button, Modal } from "components/core";
import { useState } from "react";
import { IListOption } from "helpers/formUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";

type MortalityProps = {
  label: string;
  id: string;
  value: string;
  setValue: (value: string) => void;
  setValid: (valid: boolean) => void;
  hatch: {
    id: string;
    label: string;
  }[];
  listOptions: IListOption[];
};

/**
 * Expected value example: mort: {\"A\": {\"Runts\": 1, \"Predator\": 2}, \"B\": { \"Runts\": \"1\" } }
 * Hatch data example: Hatch: [{ id: "A", label: "Hatch A" }, { id: "B", label: "Hatch B" }]
 * List: [{ id: "Runts", label: "Runts" }, { id: "Predator", label: "Predator" }]
 */
const Mortality: React.FC<MortalityProps> = ({
  label,
  id,
  value,
  setValue,
  setValid,
  hatch: hatches,
  listOptions,
}) => {
  const [open, setOpen] = useState(false);

  const replacedValue = value?.replace("mort:", "");
  const parsedValue = JSON.parse(replacedValue || "{}") as Record<
    string,
    Record<string, string>
  >;

  const summedValue = Object.keys(parsedValue).reduce(
    (sum, key) =>
      sum + Object.values(parsedValue[key]).reduce((a, b) => a + Number(b), 0),
    0
  );

  function handleInputChange(
    rawInputValue: string,
    hatchId: string,
    optionValue: string
  ) {
    const sanitizedValue = rawInputValue.replace(/[^0-9]/g, "");
    const newParsedValue = {
      ...parsedValue,
      [hatchId]: {
        ...parsedValue[hatchId],
        [optionValue]: isNullEmptyOrWhitespace(sanitizedValue) ? "0" : sanitizedValue,
      },
    };
    setValue(`mort:${JSON.stringify(newParsedValue)}`);
  }

  if (isNullEmptyOrWhitespace(hatches)) {
    // Hatches are required
    return null;
  }

  return (
    <>
      <Button type="button" onClick={() => setOpen(true)}>
        Mortality: {summedValue}
      </Button>
      <Modal
        title="Edit Mortality"
        open={open}
        onOpen={setOpen}
        showCancel={true}
        cancelButtonText="Close"
      >
        <div className="py-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <TableHeaders hatches={hatches} />
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {listOptions.map((option) => (
                <TableRow
                  key={option.Id}
                  option={option}
                  hatches={hatches}
                  parsedValue={parsedValue}
                  onInputChange={handleInputChange}
                />
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

const TableHeaders: React.FC<{ hatches: MortalityProps["hatch"] }> = ({
  hatches,
}) => (
  <tr>
    <th></th>
    {hatches.map((hatch) => (
      <th key={hatch.id} className="text-xs text-center max-w-[80px]">
        {hatch.label}
      </th>
    ))}
  </tr>
);

const TableRow: React.FC<{
  option: IListOption;
  hatches: MortalityProps["hatch"];
  parsedValue: { [key: string]: { [key: string]: string } };
  onInputChange: (value: string, hatchId: string, optionValue: string) => void;
}> = ({ option, hatches, parsedValue, onInputChange }) => (
  <tr className="even:bg-gray-50">
    <td className="text-xs pl-2 pr-1 py-1">{option.Text}</td>
    {hatches.map((hatch) => (
      <td key={hatch.id} className="py-1">
        <input
          type="text"
          pattern="\d*"
          className="block w-full text-sm rounded-md border focus:ring-4 focus:ring-offset-0 py-1.5 bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50"
          value={parsedValue[hatch.id]?.[option.Value] ?? ""}
          placeholder="0"
          onChange={(e) =>
            onInputChange(e.target.value, hatch.id, option.Value?.toString())
          }
          onFocus={(e) => {
            if (e.target.value === "0") {
              e.target.select();
            }
          }}
        />
      </td>
    ))}
  </tr>
);

export default Mortality;
