import { PlusCircleIcon } from "lucide-react";
import type { ButtonProps } from "components/placement/Button";
import { lazy, Suspense } from "react";
import ButtonSkeleton from "components/core/Buttons/ButtonSkeleton";

const Button = lazy(() => import("components/placement/Button"));
const ButtonDropdown = lazy(
  () => import("components/placement/ButtonDropdown")
);

export interface ButtonNewProps extends ButtonProps {
  onClickOption: (formType: string, formName: string) => void;
  className?: string;
  options: { FormType: string; FormName: string; FormTitle: string }[];
}

const ButtonNew: React.FC<ButtonNewProps> = ({
  onClickOption,
  className,
  options,
  ...props
}) => {
  if (!options) return null;

  if (options.length === 1) {
    return (
      <Suspense fallback={<ButtonSkeleton />}>
        <Button
          variant="primary"
          className={className}
          onClick={(ev) => {
            ev.preventDefault();
            onClickOption(
              options[0].FormType.toLowerCase(),
              options[0].FormName.toLowerCase()
            );
          }}
          {...props}
        >
          <PlusCircleIcon className="w-6 h-6 mr-2 text-white/50 group-hover:text-white" /> New
        </Button>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<ButtonSkeleton />}>
      <ButtonDropdown
        variant="primary"
        options={options.map((o) => ({
          key: o.FormName.toLowerCase(),
          label: o.FormTitle,
          className: "cursor-pointer hover:text-primary",
          onSelect: (ev) => {
            onClickOption(o.FormType.toLowerCase(), o.FormName.toLowerCase());
          },
        }))}
        contentProps={{
          align: "end",
        }}
        {...props}
      >
        <PlusCircleIcon className="w-6 h-6 mr-2" /> New
      </ButtonDropdown>
    </Suspense>
  );
};

export default ButtonNew;
