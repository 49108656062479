import { isNullEmptyOrWhitespace } from "./stringUtilities";

export const round = (value) => {
  if (isNaN(value)) return value;
  value = Number(value);

  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const roundToNearest = (value, numDecimalPlaces = 2) => {
  if (isNaN(value)) return value;
  value = Number(value);

  return Math.round((value + Number.EPSILON) * Math.pow(10, numDecimalPlaces)) / Math.pow(10, numDecimalPlaces);
}

/**
 * Truncate value to X decimal places
 * @param {string|number} value
 * @param {number} numDecimalPlaces
 * @returns If more than 'numDecimalPlaces' truncate to 'numDecimalPlaces' decimal places
 */
export const roundNaturally = (value, numDecimalPlaces = 2) => {
  if (isNaN(value)) return value;

  value = value.toString();
  value = value.replace(/^0+(?=[0-9])/, ""); // Remove leading zeros
  value = value.replace(/^\./, "0."); //  Insert leading zero

  let decimalPointIndex = value.indexOf(".");
  if (decimalPointIndex > -1) {
    // Has a decimal point and
    value = value.slice(0, decimalPointIndex + numDecimalPlaces + 1);

    if (numDecimalPlaces === 0) {
      // ensure it doesn't end in with decimal 1.
      value = value.replace(".", "");
    }
  }

  return value;
};

/**
 * Calculate the Coefficient of Variation
 *
 * @param {[string|number]} - Array of values.
 * @returns {number}  - The Coefficient of Variation value.
 */
export const calcCoefficiencyVariance = (values) => {
  let valueLessMeanTotal = 0;
  const lengthLess1 = values.length - 1;
  for (const v of values) {
    valueLessMeanTotal += Math.pow(v - calcAverage(values), 2);
  }
  const result =
    (Math.sqrt(valueLessMeanTotal * (1 / lengthLess1)) / calcAverage(values)) *
    100;
  if (isNaN(result)) return 0;

  return result.toFixed(4);
};

/**
 * Calculate the Average
 *
 * @param {[string|number]} - Array of values.
 * @returns {number}  - The Average value.
 */
export const calcAverage = (values) => {
  const result = values.reduce((acc, v) => (acc += Number(v)), 0);

  if (!values.length || !result) return 0; // Prevent division by zero

  return result / values.length;
};

/**
 * Calculate the Standard Deviation
 *
 * @param {[string|number]} - Array of values.
 * @returns {number}  - The Standard Deviation value.
 */
export const calcStandardDeviation = (values) => {
  const avg = calcAverage(values);

  const squareDiffs = values.map((value) => {
    const diff = Number(value) - avg;

    return diff * diff;
  });

  const avgSquareDiff = calcAverage(squareDiffs);

  return Math.sqrt(avgSquareDiff);
};

export const calculateTextColorFromBackgroundColor = (backgroundColor) => {
  if (isNullEmptyOrWhitespace(backgroundColor)) return undefined;

  const rgb = backgroundColor
    .replace("rgb(", "")
    .replace(")", "")
    .split(",")
    .map((value) => parseInt(value, 10));
  const brightness = Math.round(
    (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
  );

  return brightness > 125 ? "black" : "white";
};