import {
  CheckCircle,
  Clock,
  AlertCircle,
  PlusCircle,
} from "lucide-react";
import { FORM_DATA_QUEUE_STATUS } from "db/FormDataQueue";

const ListIcon: React.FC<{ status: FORM_DATA_QUEUE_STATUS }> = ({ status }) => {
  function getIcon(status: FORM_DATA_QUEUE_STATUS) {
    switch (status) {
      case FORM_DATA_QUEUE_STATUS.SENT:
        return <CheckCircle className="w-10 h-10 mr-2 text-success-400" />;
      case FORM_DATA_QUEUE_STATUS.PENDING:
        return <Clock className="w-10 h-10 mr-2 text-warning-400" />;
      case FORM_DATA_QUEUE_STATUS.FAILED:
        return <AlertCircle className="w-10 h-10 mr-2 text-danger-400" />;
      default:
        return <PlusCircle className="w-10 h-10 mr-2 text-gray-400" />;
    }
  }

  const Icon = getIcon(status); // Add the correct type for Icon

  return Icon;
};

export default ListIcon;
