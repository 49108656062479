import useSyncData from "hooks/useSyncData";
import SyncPendingNotification from "./SyncPendingNotification";
import SyncFailedNotification from "./SyncFailedNotification";

const SyncNotification: React.FC = () => {
  const { failedCount, pendingCount } = useSyncData();

  if (pendingCount > 0) {
    return <SyncPendingNotification count={pendingCount} />;
  }

  if (failedCount > 0) {
    return <SyncFailedNotification count={failedCount} />;
  }

  return null;
};

export default SyncNotification;