import Card from "components/Card";
import SigninForm from "components/user/signin-form";
import SigninLogos from "components/user/signin-logos";
import Logo from "assets/Logo";
import { Alert } from "components/core";

/**
 * Uncomment the following line to test on local development
 * overwrite subdomain with client subdomain
 */
// if (process.env.NODE_ENV === "development") subdomain = "ahwnistaging";
export default function Login() {
  
  return (
    <>
      <div className="flex min-h-screen flex-1 items-center bg-gray-100 text-center">
        <div className="flex-1 flex-row justify-center space-y-2">
          <div className="mx-auto w-full max-w-xl p-4 tablet:p-0">
            <Card className="p-4 tablet:p-8" bodyClassName="space-y-6">
              <Logo className="mx-auto h-24 w-auto text-gray-900" />
              {!navigator.onLine ? (
                <Alert theme="warning">You are offline. Please check your internet connection.</Alert>
              ) : (
                <SigninForm />
              )}
            </Card>
            <div className="mx-auto w-60 tablet:w-72">
              <SigninLogos />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
