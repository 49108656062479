import React, { useState, useEffect } from "react";
import { InitialsAvatar } from "../Avatars/InitialsAvatar";
import { ChevronRight } from "lucide-react";
import { generateInitialsFromText } from "helpers/stringUtilities";

export interface IListItemProps {
  id?: string;
  children: React.ReactNode;
  avatarText?: string;
  size?: "small" | "medium" | "large";
  theme?: "primary" | "secondary" | "tertiary" | "danger" | "success" | "warning" | "gray" | undefined;
  isSelected?: boolean;
  showClickIcon?: boolean;
  clickIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  innerRef?: React.LegacyRef<HTMLLIElement> | undefined;
}

export default function ListItem({
  children,
  avatarText = undefined,
  size = undefined,
  theme = undefined,
  isSelected = undefined,
  showClickIcon = true,
  clickIcon = <ChevronRight key="clickIcon" className="w-4 h-6" />,
  onClick = undefined,
  className = "",
  innerRef,
  ...other
}: IListItemProps) {
  const [initials, setInitials] = useState("");
  const classesArr = ["hover:bg-primary-lightest", className];

  useEffect(() => {
    if (avatarText && avatarText !== initials) {
      setInitials(
        avatarText.length > 3
          ? generateInitialsFromText(avatarText)
          : avatarText
      );
    }
  }, [avatarText, initials]);

  // Size
  if (size === "small") {
    classesArr.push("px-4 py-2");
  } else {
    classesArr.push("px-4 py-4");
  }

  // Active item
  if (isSelected) {
    classesArr.push("bg-primary text-white");
  }

  // onClick
  if (onClick) {
    classesArr.push("cursor-pointer");
  }

  return (
    <li
      data-cy="list-item"
      className={classesArr.join(" ")}
      onClick={onClick}
      ref={innerRef}
      {...other}
    >
      <div className="flex items-center">
        <div className="min-w-0 flex-1 flex items-center">
          <InitialsAvatar key="avatar" initials={initials} className="mr-3" />
          {children}
        </div>
        {!!onClick && showClickIcon && (clickIcon)}
      </div>
    </li>
  );
}
