import classNames from "classnames";
import { cn } from "helpers/common";

interface ITooltipProps {
  children: React.ReactNode;
  color?: string | null;
  [key: string]: any;
}

export function Tooltip({
  color,
  children,
  className,
  ...rest
}: ITooltipProps) {
  return (
    <div
      {...rest}
      className={classNames(
        "rounded text-sm flex flex-col divide-y divide-gray-200",
        className
      )}
      style={{
        background: "white",
        padding: "9px 12px",
        borderLeft: "5px solid",
        borderColor: color ?? undefined,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      {children}
    </div>
  );
}

type TooltipItemProps = {
  label: string;
  value: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const TooltipItem: React.FC<TooltipItemProps> = ({
  label,
  value,
  className,
  style,
  ...rest
}) => {
  return (
    <div
      className={cn("grid grid-cols-2 gap-4 px-2", className)}
      style={style}
      {...rest}
    >
      <div className="">{label}:</div>
      <div className="font-medium">{value}</div>
    </div>
  );
};
