import { type IUser } from "helpers/formUtilities";
import { useUserGetMe } from "hooks/useUser";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import cookie from "cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export interface IUserContext {
  user: IUser | undefined;
  setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  isSignedIn: boolean;
  isLoading: boolean;
}

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserContext = createContext({} as IUserContext);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<IUser | undefined>(undefined);

  const navigate = useNavigate();

  const { error: errorUser, data: userData, isLoading: isLoadingUser } = useUserGetMe({
    onError: (errMessage) => {
      toast.error(errMessage ?? "Error getting user data.");

      navigate("/user/login");
    },
  });

  const checkIsSignedIn = useCallback(() => {
    const cookies = cookie.parse(document.cookie);
    const _isSignedInCookie = cookies.signedIn === "true";

    return _isSignedInCookie;
  }, []);

  const [isSignedIn, setIsSignedIn] = useState<boolean>(checkIsSignedIn());

  useEffect(() => {
    const isSignedIn = checkIsSignedIn();
    setIsSignedIn(isSignedIn);
  }, [checkIsSignedIn]);

  useEffect(() => {
    if (errorUser) {
      console.log(errorUser);
    }
  }, [errorUser]);

  useEffect(() => {
    setUser(userData);
  }, [setUser, userData]);

  const contextValue = useMemo(
    () => ({ user, setUser, isSignedIn, isLoading: isLoadingUser }),
    [user, setUser, isSignedIn, isLoadingUser]
  );

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};
