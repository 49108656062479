import { NetworkStatusContext } from "context/NetworkStatusProvider";
import { useContext } from "react";

const useNetworkStatus = () => {
  const context = useContext(NetworkStatusContext);

  if (!context) {
    throw new Error("useNetworkStatus must be used within a NetworkStatusProvider");
  }

  return context;
}

export default useNetworkStatus;
