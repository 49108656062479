import { createContext, useEffect, useState } from "react";

export interface INetworkContext {
  isOnline: boolean;
}

const NetworkStatusContext = createContext({} as { isOnline: boolean });

const NetworkStatusProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    updateOnlineStatus();

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return (
    <NetworkStatusContext.Provider
      value={{
        isOnline,
      }}
    >
      {children}
    </NetworkStatusContext.Provider>
  );
};

export { NetworkStatusContext, NetworkStatusProvider as default };