import { localDate, dateDiffInDays, endOfFromDate, dateSubtract, startOfWeekFromDate, dateAdd } from "./dateUtilities";
import { ISQLDateObject } from "./formUtilities";

export interface IFarmFlock {
  [key: string]: IFarmFlockData[];
}

export interface IFarmFlockData {
  HouseNumber: number;
  HouseLabel: string;
  Pens: { PenNumber: number; }[];
}

export interface IFarm {
  FarmCode: string;
  FarmGroup: string;
  FarmName: string;
  FarmType: string;
  Houses: IFarmHouse[];
  Flocks: IFarmFlock;
  BinMin?: number;
  BinMax?: number;
}

export interface IFarmHouse {
  HouseLabel: string;
  HouseNumber: number;
  WaterMeterType: string;
  Pens: IFarmPen[];
  Bins?: {
    Label: string;
  }[];
}

export interface IFarmPen {
  PenNumber: number;
  BirdsAlive: number;
  ExcludedRefs?: string;
  Placement: IFarmPlacement;
  Label?: string;
}

export interface IFarmPlacement {
  HatchDate: string;
  _HatchDate: ISQLDateObject;
  CropDate: string;
  _CropDate: ISQLDateObject;
  DatePlaced: string;
  _DatePlaced: ISQLDateObject;
  DepopDate: string;
  BirdsPlaced: number;
  BirdSex: string;
  BirdType: string;
}

export function getFarm(farms: IFarm[], farmCode: string): IFarm | null {
  return farms.find(
    (farm) => farm.FarmCode?.toLowerCase() === farmCode.toLowerCase()
  ) ?? null;
}

export function getFarmHouse(farm: IFarm, houseNumber: number): IFarmHouse | null {
  return farm.Houses.find((house) => house.HouseNumber === houseNumber) ?? null;
}

export function getFarmHousePen(
  farmHouse: IFarmHouse,
  penNumber: number | string
): IFarmPen | undefined {
  const penNumberInt = typeof penNumber === "string" ? parseInt(penNumber) : penNumber;

  return farmHouse?.Pens?.find((pen) => pen.PenNumber === penNumberInt);
}

export function getPlacementFromHouse(house: IFarmHouse, penNumber: number = 1) {
  return (
    house.Pens.find((pen) => pen.PenNumber === penNumber)?.Placement ?? null
  );
}

/**
 * 
 * @param placementDate When undefined, defaults to maxDaysSincePlacement: 20
 * @returns 
 */
export function calcProductionFormDataDates(placementDate?: Date) {
  const dateToday = localDate();
  const maxDaysSincePlacement = 20;

  let numDaysSincePlacement = dateDiffInDays(
    placementDate ?? undefined,
    dateToday
  );

  if (numDaysSincePlacement === undefined) {
    numDaysSincePlacement = maxDaysSincePlacement;
  }

  // Round down to nearest whole number
  numDaysSincePlacement = numDaysSincePlacement > 0 ? Math.floor(numDaysSincePlacement) : 0;

  // Limit to 20 days
  if (numDaysSincePlacement > maxDaysSincePlacement) {
    numDaysSincePlacement = maxDaysSincePlacement;
  }

  // Add a day to the end of the range to include today
  numDaysSincePlacement += 1;

  // Production/schedules/weeklyproduction dates are all based off of DatePlaced
  const toDate = endOfFromDate(localDate(), "day");
  const fromDate = dateSubtract(toDate, numDaysSincePlacement, "day");

  return { fromDate, toDate, numDaysSincePlacement };
}

export function calcWeeklyProductionDates(formDate: Date, dayOfWeek: number) {
  let fromDate = startOfWeekFromDate(formDate, {
    offset: dayOfWeek - 1,
  });
  if (fromDate > formDate) {
    fromDate = dateAdd(fromDate, -1, "weeks");
  }

  const toDate = dateAdd(fromDate, 6, "days");

  return { fromDate, toDate };
}

export function calcGenericFormDataDates() {
  const dateToday = localDate();
  const toDate = endOfFromDate(dateToday, "day");
  const fromDate = dateSubtract(toDate, 90, "day");

  return { fromDate, toDate };
}
