import useFetch from "./useFetch";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FarmsContext } from "context/FarmProvider";

const useFarms = () => {
  const context = useContext(FarmsContext);

  if (!context) {
    throw new Error("useFarms must be used within a AppDataProvider");
  }

  return context;
};

type useFarmGetManyProps = {
  enabled?: boolean;
};

export const useFarmGetMany = ({ enabled = true }: useFarmGetManyProps = {}) => {
  const { data } = useFarms();

  return { data };
};

export const useFarmGetOne = (farmCode: string) => {
  const { data: farms } = useFarms();

  const farm = useMemo(() => {
    if (!farmCode) {
      return undefined;
    }
    return farms.find(
      (f) => f.FarmCode.toLowerCase() === farmCode.toLowerCase()
    );
  }, [farms, farmCode]);

  return farm;
}

type useFarmGetGroupsProps = {
  enabled?: boolean;
};

export const useFarmGetGroups = ({ enabled = true }: useFarmGetGroupsProps = {}) => {
  const { isLoading, error, execute } = useFetch();

  const [data, setData] = useState<string[] | undefined | null>(
    undefined
  );

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/farmgroups-get");

    const newData = data?.d ?? [];

    setData(newData);

    return newData;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, farmGroups: data };
}