import { useEffect, useState, useRef } from "react";
import AppUpdateNotification from "components/AppUpdateNotification";
import BackgroundSyncNotification from "components/BackgroundSyncNotification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pages from "routes/Pages";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
// import { insertSampleFormValuesToCache } from "hooks/useFormData";

const version = require("../package.json").version;
export const AppVersion = `v${version}`;

export default function App({ instance }) {
  const [swStatus, setSwStatus] = useState("");

  const waitingWorkerRef = useRef(null);

  const handleSwUpdateAvailable = (ev) => {
    console.log("update available");
    waitingWorkerRef.current = ev.detail.waiting;
    setSwStatus("update_available");
  };

  const handleSwInstalling = () => {
    console.log("installing");
    setSwStatus("installing");
  };

  useEffect(() => {
    document.addEventListener(
      "serviceWorkerUpdateAvailable",
      handleSwUpdateAvailable
    );

    document.removeEventListener("serviceWorkerInstalling", handleSwInstalling);

    // insertSampleFormValuesToCache();

    return () => {
      document.removeEventListener(
        "serviceWorkerUpdateAvailable",
        handleSwUpdateAvailable
      );
      document.removeEventListener(
        "serviceWorkerInstalling",
        handleSwInstalling
      );
    };
  }, []);

  useEffect(() => {
    if (swStatus === "installed") {
      setTimeout(() => {
        setSwStatus("");
      }, 3000);
    }
  }, [swStatus]);

  const handleUpdateApp = () => {
    setSwStatus("installing");

    // delay to allow UI to update
    setTimeout(() => {
      waitingWorkerRef.current.postMessage({ type: "SKIP_WAITING" });
    }, 2000);

    waitingWorkerRef.current.addEventListener("statechange", (e) => {
      // Wait for new service worker to become active
      if (e.target.state === "activated") {
        console.log("activated");
        setSwStatus("installed");

        // Remove the event listener since it's no longer needed
        waitingWorkerRef.current = null;

        window.location.reload();
      }
    });

    // reset status after 10 seconds
    setTimeout(() => {
      if (!isNullEmptyOrWhitespace(swStatus)) {
        console.warn("Resetting SW status due to timeout");
        setSwStatus("");
      }
    }, 10000);
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AppUpdateNotification status={swStatus} onClick={handleUpdateApp} />
      <BackgroundSyncNotification />
      <Pages />
    </>
  );
}



