import { DBFormDataQueue, FORM_DATA_QUEUE_STATUS } from "db/FormDataQueue";
import ListItemIcon from "./ListItemIcon";
import ListItemStatus from "./ListItemStatus";
import { Button, ListItem as ListItemCore } from "components/core";
import { dateToString, localDateFromUnix } from "helpers/dateUtilities";
import { MAX_SEND_ATTEMPTS } from "context/SyncDataProvider";
import { useFarmGetOne } from "hooks/useFarm";

function Date({
  epoch,
  includeTime,
}: {
  epoch: number;
  includeTime?: boolean;
}) {
  const date = localDateFromUnix(epoch);

  if (!date) {
    return null;
  }

  return (
    <time dateTime={date.toISOString()}>
      {dateToString(date, { includeTime })}
    </time>
  );
}

const ListItem: React.FC<{
  item: DBFormDataQueue;
  handleResetSendAttempts: (item: DBFormDataQueue) => void;
}> = ({ item, handleResetSendAttempts }) => {
  const farm = useFarmGetOne(item.farmCode);

  return (
    <ListItemCore>
      <div className="min-w-0 grow flex items-center">
        <ListItemIcon status={item.sendStatus} />
        <div className="flex-grow">
          <div className="text-sm font-medium">
            {farm?.FarmName} ({item.farmCode})
          </div>
          <div className="tablet:flex tablet:space-x-2">
            <div className="font-medium">{item.formName}</div>
            <div className="text-sm text-gray-900" data-cy="date-range">
              <Date epoch={item.dateApplies} />
            </div>
          </div>
          <div className="text-xs text-gray-400">
            <div className="flex space-x-2">
              <div>
                Updated: <Date epoch={item.lastModified} includeTime />
              </div>
            </div>
          </div>
          <div className="flex text-xs uppercase mt-1">
            <ListItemStatus status={item.sendStatus} />
          </div>
          <div className="text-sm">
            Attempts: {item.sendAttempts} / {MAX_SEND_ATTEMPTS}
          </div>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        {item.sendStatus === FORM_DATA_QUEUE_STATUS.FAILED && (
          <Button
            theme="danger"
            size="small"
            onClick={() => handleResetSendAttempts(item)}
          >
            Reset attempts
          </Button>
        )}
      </div>
    </ListItemCore>
  );
};

export default ListItem;
