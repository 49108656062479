import { ChevronRightIcon, XIcon, SearchIcon } from "lucide-react";
import React, { useEffect, useState, lazy, Suspense, useMemo } from "react";
import ReactDOM from "react-dom";
import { Badge } from "../Badges";
import List from "../Lists/List";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";
import { convertHexToRGBA, isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { Input, Label } from "..";
import ListItem from "../Lists/ListItem";
import { isEqual } from "helpers/comparisionUtilities";
import useDebounceEffect from "hooks/useDebounceEffect";
import useCalcTrigger from "hooks/useCalcTrigger";
import classNames from "classnames";
import { IFormValue, IListOption } from "helpers/formUtilities";

const ReactSortable = lazy(() =>
  import("react-sortablejs").then((module) => ({
    default: module.ReactSortable,
  }))
);

interface IMultiSelectProps {
  label: string;
  id: string;
  hint?: string;
  validate?: (value: string) => boolean;
  value: IFormValue["Value"];
  setValue: (value: string) => void;
  setValid?: (
    valid: boolean,
    value: IFormValue["Value"],
    required: boolean,
    complete: boolean
  ) => void;
  required?: boolean;
  disabled?: boolean;
  labelPosition?: "top" | "left" | "inset";
  labelSize?: "sm" | "md" | "lg";
  style?: React.CSSProperties;
  dependencies?: string[];
  className?: string;
  inputClassName?: string;
  defaultValue?: IFormValue["Value"];
  size?: "sm" | "md" | "lg";
  render?: boolean;
  listOptions: IListOption[];
  asPortal?: boolean;
  showSearch?: boolean;
  sortable?: boolean;
  limit?: number;
  addonLeft?: React.ReactNode;
  addonRight?: React.ReactNode;
  disableCalcTrigger?: boolean;
}

const MultiSelect = ({
  label,
  id,
  hint = "",
  validate = () => true,
  value,
  setValue,
  setValid = () => {},
  required = true,
  disabled = false,
  labelPosition = "top",
  labelSize,
  style = {},
  dependencies = [],
  className = "",
  inputClassName = "",
  defaultValue,
  size,
  render = true,
  listOptions,
  asPortal = false, // If true, will render the component as a portal
  showSearch = false, // If true, will show the search input,
  sortable = false, // If true, will allow the list to be sortable
  limit = 0, // If greater than 0, will limit the number of selected items
  addonLeft = undefined,
  addonRight = undefined,
  disableCalcTrigger = false,
  ...other
}: IMultiSelectProps) => {
  // state showing if dropdown is open or closed
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  const [focused, setFocused] = useState(false);

  const valueAsString = (value?.toString() ?? "") as string;
  const defaultValueAsString = (defaultValue ?? "") as string;
  const [hasValue, setHasValue] = useState(valueAsString.length > 0);

  useCalcTrigger(valueAsString, setValue, disableCalcTrigger);

  const hasDefaultValueRef = React.useRef(false); // Prevent retriggering of default value

  const selectedListOptionValues = useMemo(
    () => valueAsString.trim().split(",").filter(Boolean),
    [valueAsString]
  );

  const selectedListOptions = useMemo(() => {
    if (!listOptions) {
      return [];
    }

    // respect the order of the selected values
    const listOptionLookup = listOptions.reduce((acc, cur) => {
      if (isNullEmptyOrWhitespace(cur.Value)) return acc;

      acc[cur.Value.toString()] = {
        ...cur,
        Value: cur.Value.toString(), // Ensure the value is a string, important for comparison later
      };
      return acc;
    }, {} as Record<string, IListOption>);

    // remove any values that are not in the list options
    const filteredSelectedListOptionValues = selectedListOptionValues.filter(
      (value) => value in listOptionLookup
    );

    return filteredSelectedListOptionValues.map((value) => listOptionLookup[value]);

  }, [listOptions, selectedListOptionValues]);

  const unselectedListOptions = useMemo(() => {
    if (!listOptions) {
      return [];
    }

    return listOptions.filter(
      (option) => {
        const valueAsString = option.Value?.toString() ?? "";
        return !selectedListOptionValues.includes(valueAsString)
      }
    );
  }, [listOptions, selectedListOptionValues]);

  // Popper
  const [buttonElement, setButtonElement] = useState<any>(undefined);
  const [popperElement, setPopperElement] = useState<any>(undefined);
  const { styles, attributes } = usePopper(buttonElement, popperElement, {
    modifiers: [
      { name: "offset", options: { offset: [0, -10] } },
      // { name: "arrow", options: { element: arrowElement } },
    ],
  });

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} newValue  The new input value.
   */
  const setInputValue = React.useCallback(
    (newValue: string) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof newValue === "undefined" || newValue === null) return;

      if (!isEqual(newValue?.toString(), value?.toString())) {
        setValue(newValue);
      }
      setHasValue(newValue.length > 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, setHasValue]
  );

  const validation = () => {
    // Add custom validation here...
    // if (required && !value) {
    //   return `${label} is required.`;
    // }
    if (validate) return validate(valueAsString);

    return false;
  };

  const sortSelectedListOptions = (reorderedListOptions: IListOption[]) => {
    const newValueArray = new Set(reorderedListOptions.map((opt) => opt.Value));
    const newValue = Array.from(newValueArray).join(",");
    
    setInputValue(newValue);
  };

  //#endregion

  //#region Side-effects

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (!defaultValueAsString || !setInputValue || hasDefaultValueRef.current)
      return;

    if (isNullEmptyOrWhitespace(valueAsString)) {
      setInputValue(defaultValueAsString);
      hasDefaultValueRef.current = true;
    }
  }, [valueAsString, defaultValueAsString, setInputValue]);

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult);

    const complete = required && isNullEmptyOrWhitespace(value) ? false : true;
    if (setValid) setValid(!validationResult, value, required, complete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, value, required]);

  /**
   * TODO: this is a hack to remove any values that are not in the list options. We need to be careful not to affect the default value and custom parser.
   */
  React.useLayoutEffect(() => {
    // remove any values that are not in the list options
    selectedListOptionValues.forEach((value) => {
      if (!listOptions.some((option) => option.Value === value)) {
        removeTag(value);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListOptionValues, listOptions]);

  //#endregion

  //#region Event handlers

  const handleOptionOnClick = (selectedValue: string, close?: () => void) => {
    const newValueArray = new Set(selectedListOptionValues);
    if (!newValueArray.has(selectedValue)) {
      // Only add if doesn't already exist
      newValueArray.add(selectedValue);
    }
    
    const newValue = Array.from(newValueArray).join(",");
    setInputValue(newValue);
    setTouched(true);


    if (newValueArray.size >= limit && limit > 0) {
      // Close the dropdown if we've reached the limit
      close && close();
    }
  };

  // removes item from multiselect
  const handleRemoveTag = (ev: HTMLElement, selectedValue: string) => {
    removeTag(selectedValue);
    setTouched(true);
  };

  const removeTag = (selectedValue: string) => {
    const newValueArray = new Set(selectedListOptionValues);
    newValueArray.delete(selectedValue);

    const newValue = Array.from(newValueArray).join(",");
    setInputValue(newValue);
  };

  //#endregion

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  if (!listOptions) {
    return null;
  }

  const portalContainer = document.getElementsByTagName("BODY")[0];

  return (
    <Popover {...other}>
      {({ open }) => (
        <>
          <div
            className={`${className} ${
              labelPosition === "left" ? "grid grid-cols-3 gap-4" : "relative"
            }`}
          >
            {label && (
              <Label
                id={id}
                text={label}
                required={required}
                focused={focused}
                hasValue={hasValue}
                position={labelPosition}
                size={labelSize}
                theme={undefined}
                erroneous={touched && error && !focused}
                addonPosition={undefined}
                labelClasses={undefined}
              />
            )}
            <div
              className={`mt-1 ${labelPosition === "left" ? "col-span-2" : ""}`}
            >
              <div className="relative">
                <Popover.Button
                  as="div"
                  aria-labelledby={id}
                  data-cy="multiselect"
                  className="w-full relative cursor-pointer "
                  ref={setButtonElement}
                  // onClick={(ev) => {
                  //   ev.preventDefault();
                  //   ev.stopPropagation(); // Prevent opening the dropdown
                  // }}
                >
                  <div className="flex flex-col items-center relative">
                    <div className="w-full ">
                      {/* <div className="my-2 p-1 flex border border-gray-200 bg-white rounded "> */}
                      <div
                        className={classNames(
                          "w-full tablet:text-sm flex rounded-md border focus:ring-4 focus:ring-offset-0",
                          "bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50",
                          size === "sm" ? "py-2" : "py-3",
                          inputClassName,
                          disabled ? "disabled:opacity-50" : "",
                          touched && error && !focused
                            ? "border-danger-600"
                            : ""
                        )}
                        onClick={() => setFocused(true)}
                      >
                        <div className="flex flex-auto flex-wrap px-2">
                          {!sortable ? (
                            selectedListOptions?.length > 0 ? (
                              <SelectedItemBadges
                                selectedItems={selectedListOptions}
                                handleRemoveTag={handleRemoveTag}
                              />
                            ) : (
                              <div className="text-sm text-gray-500">
                                - Select -
                              </div>
                            )
                          ) : selectedListOptions.length > 0 ? (
                            `${selectedListOptions.length} selected`
                          ) : (
                            <div className="text-sm text-gray-500">
                              - Select -
                            </div>
                          )}
                        </div>
                        {listOptions.length > selectedListOptions.length && (
                          <div className="text-gray-300 border-l flex items-center border-gray-300">
                            <button
                              type="button"
                              className="text-gray-600 outline-none focus:outline-none"
                            >
                              <ChevronRightIcon
                                className={`h-5 w-5 transition-all duration-500 ease-in-out ${
                                  !open
                                    ? "rotate-0 text-gray-300"
                                    : "rotate-90 text-primary"
                                }`}
                              />
                              <span className="sr-only">Select option</span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Popover.Button>
                <div className="">
                  {/* Hack to all sortable elements to work inside of Popovers, we need to move it outside the Popover.Button element */}
                  {sortable ? (
                    selectedListOptions?.length > 0 ? (
                      <Suspense
                        fallback={
                          <div className="text-sm text-gray-500">
                            Loading selected items...
                          </div>
                        }
                      >
                        <ReactSortable
                          list={selectedListOptions}
                          setList={sortSelectedListOptions}
                          // draggable=".react-sortable-handle"
                          preventOnFilter={true}
                        >
                          <SelectedItemBadges
                            selectedItems={selectedListOptions.map((sid) => ({
                              Id: sid.Id,
                              Value: sid.Value,
                              Text: sid.Text,
                            }))}
                            handleRemoveTag={handleRemoveTag}
                          />
                        </ReactSortable>
                      </Suspense>
                    ) : null
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {asPortal && portalContainer ? (
            ReactDOM.createPortal(
              <PopoverPanelContent
                id={id}
                styles={styles}
                attributes={attributes}
                setPopperElement={setPopperElement}
                items={unselectedListOptions}
                selectedItems={selectedListOptions}
                handleOptionOnClick={handleOptionOnClick}
                showSearch={showSearch}
                limit={limit}
              />,
              portalContainer
            )
          ) : (
            <PopoverPanelContent
              id={id}
              styles={styles}
              attributes={attributes}
              setPopperElement={setPopperElement}
              items={unselectedListOptions}
              selectedItems={selectedListOptions}
              handleOptionOnClick={handleOptionOnClick}
              showSearch={showSearch}
              limit={limit}
            />
          )}
        </>
      )}
    </Popover>
  );
};

interface IPopoverPanelContentProps {
  id: string;
  styles: any;
  attributes: any;
  setPopperElement: any;
  items: IListOption[];
  selectedItems: IListOption[];
  handleOptionOnClick: any;
  showSearch: boolean;
  limit: number;
}

function PopoverPanelContent({
  id,
  styles,
  attributes,
  setPopperElement,
  items,
  selectedItems,
  handleOptionOnClick,
  showSearch,
  limit,
  ...other
}: IPopoverPanelContentProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<IListOption[]>([]);

  //#region Side-effects

  /**
   * Filter items
   */
  useDebounceEffect(
    () => {
      if (isNull(items)) return;

      const _filteredItems = items.filter(
        (item) =>
          isNullEmptyOrWhitespace(searchQuery) ||
          Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
      );

      setSearchResults(_filteredItems);
    },
    [searchQuery, items],
    500
  );

  //#endregion

  /**
   * Handle change search string
   * @param {string} value
   */
  const handleChangeSearchQuery = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <Popover.Panel
      ref={(ref: any) => setPopperElement(ref)}
      style={{ ...styles.popper, zIndex: 9999 }}
      className="mt-3 px-2 w-screen max-w-xs"
      {...attributes.popper}
    >
      {({ close }) => (
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="relative bg-white px-5 py-6">
            {showSearch && (
              <div className="pb-4">
                <Input
                  id="search"
                  placeholder="Filter..."
                  value={searchQuery}
                  setValue={handleChangeSearchQuery}
                  required={false}
                  addonLeft={<SearchIcon className="w-4" /> as any}
                  addonRight={
                    searchQuery && (
                      <XIcon
                        className="w-4"
                        onClick={() => setSearchQuery("")}
                      />
                    ) as any
                  }
                  addonRightInteractable={true}
                  size="sm"
                  disableCalcTrigger={true}
                  label={undefined}
                  hint={undefined}
                  validate={undefined}
                  setValid={undefined}
                  labelSize={undefined}
                  defaultValue={undefined}
                  theme={undefined}
                />
              </div>
            )}
            {searchResults.length > 0 ? (
              limit > 0 && selectedItems.length >= limit ? (
                <p className="text-sm mt-2">Maximum selections reached.</p>
              ) : (
                <List
                  id={`list-${id}`}
                  theme="striped"
                  size="small"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  {searchResults?.map((option) => (
                    <ListItem
                      id={`menu-item-${option.Id}`}
                      key={`menu-item-${option.Id}`}
                      onClick={() => handleOptionOnClick(option.Value, close)}
                    >
                      {option?.Color !== undefined ? <span style={{ color: option.Color ?? null }}>{option.Text}</span> : option.Text}
                    </ListItem>
                  ))}
                </List>
              )
            ) : (
              <p className="text-sm mt-2">No items found.</p>
            )}
          </div>
        </div>
      )}
    </Popover.Panel>
  );
}

interface ISelectedItemBadgesProps {
  selectedItems: IListOption[];
  handleRemoveTag: (ev: HTMLElement, selectedValue: string) => void;
}

function SelectedItemBadges({
  selectedItems,
  handleRemoveTag,
}: ISelectedItemBadgesProps) {
  return (
    <>
      {selectedItems.map((item) => {
        return (
          <Badge
            key={`badge-${item.Value}`}
            className="mr-1 cursor-pointer leading-none react-sortable-handle mb-1 text-xs"
            theme={item?.Color !== undefined ? "clean" : "primary"}
            iconPosition="right"
            icon={<XIcon className="w-4 h-4" />}
            title="Click to remove tag"
            onClick={(ev: any) => {
              handleRemoveTag(ev, item.Value?.toString() ?? "");
            }}
            style={{ 
              backgroundColor: item?.Color !== undefined ? convertHexToRGBA(item.Color, 0.05) : null,
              color: item?.Color !== undefined ? item.Color : null,
              borderColor: item?.Color !== undefined ? item.Color : null,
            }}
          >
            {item.Text}
          </Badge>
        );
      })}
    </>
  );
}

export { MultiSelect };
