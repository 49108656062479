import Form from "./Form";
import { useNavigate } from "react-router-dom";
import Fieldset from "components/forms/Fieldset";
import { dateAdd, endOfFromDate, startOfFromDate } from "helpers/dateUtilities";
import { getPenDataFromFormData } from "helpers/formUtilities";
import { buildListPageUrl } from "helpers/redirectUtilities";
import { useActiveMenu } from "hooks/useActiveMenu";

export default function Schedule(props) {
  const {
    handleFormSubmit,
    dataStatus,
    house,
    form,
    formValues,
    formProps,
    farm,
    schedule
  } = props;
  const navigate = useNavigate();
  const { activeMenu } = useActiveMenu();

  //#region Callbacks

  /**
   * Get the form start date
   * @returns {Date}  The start date
   */
  const getStartDate = () => {
    if (!schedule?.StartDays || !house) return null;

    const placement = getPlacement(house);
    const dateHatched = placement?._HatchDate?.normalised;

    const newDate = dateAdd(dateHatched, schedule.StartDays, "days");

    return startOfFromDate(newDate, "day");
  };

  /**
   * Get the form end date
   * @returns {Date}  The end date
   */
  const getEndDate = () => {
    if (!schedule?.EndDays || !house) return null;

    const placement = getPlacement(house);
    const dateHatched = placement?._HatchDate?.normalised;

    const newDate = dateAdd(dateHatched, schedule.EndDays, "days");

    return endOfFromDate(newDate, "day");
  };

  //#endregion

  //#region Event handlers

  /**
   * Handle click cancel button
   */
  const handleClickCancel = () => {
    return navigate(buildListPageUrl(activeMenu));
  };

  //#endregion

  return (
    <Form
      {...formProps}
      onClickCancel={handleClickCancel}
      onFormSubmit={(ev) => {
        handleFormSubmit(ev, { entryId: schedule.FarmScheduleID });
      }}
      dataStatus={dataStatus}
    >
      <Fieldset
        {...props}
        key={`fieldset-house1`}
        title={schedule?.Description}
        text="This section contains scheduled event info."
        penNumber="1"
        fields={form?.FormFields.filter((ff) => ff.Level.toLowerCase() === "h")}
        formValues={getPenDataFromFormData("1", formValues)}
        startDate={getStartDate()}
        endDate={getEndDate()}
        farm={farm}
      />
    </Form>
  );
}

function getPlacement(house) {
  if (!house?.Pens?.length) return null;

  return house.Pens.find((p) => p.PenNumber.toString() === "1").Placement;
}
