import { FORM_DATA_QUEUE_STATUS } from "db/FormDataQueue";

const ListItemStatus: React.FC<{ status: FORM_DATA_QUEUE_STATUS }> = ({
  status,
}) => {
  function getStatus(status: FORM_DATA_QUEUE_STATUS) {
    switch (status) {
      case FORM_DATA_QUEUE_STATUS.SENT:
        return <span className="text-success-400">Sent</span>;
      case FORM_DATA_QUEUE_STATUS.PENDING:
        return <span className="text-warning-400">Pending</span>;
      case FORM_DATA_QUEUE_STATUS.FAILED:
        return <span className="text-danger-400">Failed</span>;
      default:
        return <span className="text-gray-400">Unknown</span>;
    }
  }

  const Status = getStatus(status); // Add the correct type for Status

  return Status;
};

export default ListItemStatus;
