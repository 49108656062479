import { SyncDataContext } from "context/SyncDataProvider";
import { useContext } from "react";

const useSyncData = () => {
  const context = useContext(SyncDataContext);

  if (!context) {
    throw new Error("useSyncData must be used within a SyncDataProvider");
  }

  return context;
}

export default useSyncData
