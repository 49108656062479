import { useState, useCallback, useRef } from "react";
import type { onSuccessSchema, onErrorSchema } from "./useFormData";


const useFetch = ({ onSuccess, onError } : {
  onSuccess?: onSuccessSchema,
  onError?: onErrorSchema,
} = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [error, setError] = useState<string>("");
  const [data, setData] = useState(null);

  const requestCounter = useRef(0);

  const execute = async (method: string, endpoint: string, data: string | FormData | object | null = null, variables = undefined) => {
    const currentRequestId = ++requestCounter.current;
    setIsLoading(true);

    try {
      const headers = new Headers();

      if (data) {
        // GET requests cannot have a body
        if (!(data instanceof FormData)) {
          // Some POST, PUT, DELETE requests require a JSON body
          // while others require a FormData body
          headers.append("Content-Type", "application/json");
        }
      }

      /* body can be FormData or JSON */
      const body = !!data
        ? data instanceof FormData
          ? data
          : JSON.stringify(data ?? {})
        : null;

      let options = {
        method: method,
        headers: headers,
        body,
      };

      /* Important: endpoint must be lowercase for SWA to work correctly */
      endpoint = endpoint.toLowerCase();

      const response = await fetch(endpoint, options);

      // Handle downloads
      let responseBody;
      if (response.headers.get("content-disposition")) {
        responseBody = await response.blob();
      } else {
        responseBody = await response.json();
      }

      if (!response.ok) {
        const errMessage = responseBody?.message ?? "Error request failed.";
        setError(errMessage);
        onError?.(errMessage, variables);

        return { requestId: currentRequestId, error: errMessage, data: responseBody };
      }

      setData(responseBody);
      onSuccess?.(response, responseBody, variables);

      return { requestId: currentRequestId, data: responseBody };
    } catch (e) {
      /**
       * If the request fails due to a network error, just ignore it.
      */
      if (navigator.onLine) {
        console.error("Error: ", e);
        const errMessage = "Error request failed.";
        setError(errMessage);
        onError?.(errMessage, variables);
      }

      // throw e;
      return { requestId: currentRequestId, error: "Error request failed.", data: null };
    } finally {
      setIsLoading(false);
      setIsFetched(true);
    }
  }

  return {
    isLoading,
    isFetched,
    error,
    data,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    execute: useCallback(execute, []), // to avoid infinite calls when inside a `useEffect`
  };
};

export default useFetch;
