import useNetworkStatus from "hooks/useNetworkStatus";
import { Alert } from "./core";

export default function OfflineNotification() {
  const { isOnline } = useNetworkStatus();

  if (isOnline) {
    return null;
  }

  return (
    <div>
      <Alert
        theme="warning"
        size="sm"
        className="cursor-pointer"
      >
        <p className="text-sm font-medium">App offline</p>
        <p>You are currently offline. Data may not be up to date. Some features will be disabled.</p>
        <p>Submissions will be saved with "pending" status until you are back online.</p>
      </Alert>
    </div>
  );
}
