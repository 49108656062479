import { Alert } from "./core";
import { useNavigate } from "react-router-dom";

export default function SyncFailedNotification({ count }: { count: number }) {
  const navigate = useNavigate();

  if (count <= 0) {
    return null;
  }

  const handleMoreInfo = () => {
    navigate("/sync-manager");
    return;
  };

  return (
    <div>
      <Alert
        theme="danger"
        size="sm"
        className="cursor-pointer"
        onClick={handleMoreInfo}
      >
        <p className="text-sm font-medium">Sync data failed</p>
        <p>
          You have {count} "failed" submission(s). Click here for more info.
        </p>
      </Alert>
    </div>
  );
}
