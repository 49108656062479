import { useCallback, useContext, useEffect, useState } from "react";
import { ConfigContext } from "context/ConfigProvider";

const useConfig = () => {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }

  return context;
};

type useConfigGetManyProps = {
  enabled?: boolean;
  keys?: string;
};

const defaultData: Record<string, string> = {};

const useConfigGetMany = ({ enabled = true, keys = "" }: useConfigGetManyProps = {}) => {
  const { data: config } = useConfig();

  const [isLoading, setIsLoading] = useState<boolean>(enabled);
  const [data, setData] = useState<Record<string, string>>(defaultData);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const keysArray = keys?.split(",") || [];

    const newData = keys?.length ? keysArray.reduce((acc, key) => {
      if (config[key] !== undefined) {
        acc[key] = config[key];
      }
      return acc;
    }, defaultData) : config;
    
    setData(newData);

    setIsLoading(false);

    return newData;
  }, [config, keys]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    data,
    isLoading
  };
};

export { useConfig, useConfigGetMany };
