import React, {
  createContext, useCallback, useEffect, useState
} from "react";
import useFetch from "hooks/useFetch";
import { useUser } from "hooks/useUser";

export interface IConfigContext {
  data: Record<string, string>;
}

const ConfigContext = createContext({} as IConfigContext);

const defaultData: Record<string, string> = {};

const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const { execute } = useFetch();

  const { isSignedIn } = useUser();

  const [data, setData] = useState<Record<string, string>>(defaultData);

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/config-get");

    setData(data ?? defaultData);

    return data;
  }, [execute]);

  useEffect(() => {
    if (isSignedIn) {
      fetchData();
    }
  }, [isSignedIn, fetchData]);

  return (
    <ConfigContext.Provider
      value={{
        data,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigContext, ConfigProvider as default }
