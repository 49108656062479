import React from "react";
import { cn } from "helpers/common";

interface SectionTitleProps {
  children: React.ReactNode;
  className?: string;
}

export default function SectionTitle({
  children,
  className,
}: SectionTitleProps) {
  return (
    <div
      className={cn(
        className,
        "text-lg font-medium text-gray-600 flex flex-col uppercase"
      )}
    >
      {children}
    </div>
  );
}
