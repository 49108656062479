import { useContext } from "react";
import { FormContext } from "context/FormProvider";

const useForms = () => {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error("useForms must be used within a AppDataProvider");
  }

  return context;
};

export const useFormGetMany = () => {
  const { data, isLoading, isFetched } = useForms();

  return {
    isLoading,
    isFetched,
    data,
  };
};
