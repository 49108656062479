import { Textarea } from "components/placement/Textarea";
import { cn } from "helpers/common";
import React from "react";

interface NoteChartProps {
  id: string;
  keys: {
    id: string;
    title: string;
    dataSource: string;
  }[];
  settings: {
    showTitle: boolean;
  };
  className?: string;
  note?: {
    ID: string;
    ChartID: string;
    Note: string;
  };
  save: (id: string, data: string) => void;
  // eventFunctions?: {
  // };
}

export const NoteChart: React.FC<NoteChartProps> = ({
  id,
  keys,
  className = "",
  settings,
  note,
  save,
}) => {
  return (
    <div className={cn("px-4 py-5 tablet:p-6", className)}>
      <Textarea
        className="rounded-md border border-gray-100 focus:border-gray-200 mx-0 my-0"
        placeholder="Type a note..."
        maxLength={200}
        defaultValue={note?.Note}
        onBlur={(e) => {
          save(id, e.target.value);
        }}
      />
    </div>
  );
};
