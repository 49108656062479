import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";
import theme from "./theme.json";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { line } from "d3-shape";
import { Tooltip, TooltipItem } from "components/Dashboard/Tooltip";
import { DEFAULT_CHART_COLORS } from "./DefaultChartColours";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
/**
 * Line Chart
 * @see https://nivo.rocks/line/
 * @returns
 */
export const LineChart = ({
  id,
  keys = [],
  attrs,
  settings,
  data = [],
  standardData = [],
}) => {
  const [areaBaseLineValue, setAreaBaseLineValue] = useState(0);
  const [curveType, setCurveType] = useState("monotoneX");

  useEffect(() => {
    if (!data?.length) return;
    // Build flat array of all data values
    const valuesArray = [];
    for (const dataObj of data) {
      for (const value of dataObj.data) {
        valuesArray.push(value.y);
      }
    }
    //console.log(valuesArray);

    const min = Math.min(...valuesArray);
    //console.log(min);
    setAreaBaseLineValue(min);
  }, [data]);

  useEffect(() => {
    if (!isNullEmptyOrWhitespace(attrs?.curveType))
      setCurveType(attrs.curveType);
  }, [attrs?.curveType]);

  const LineLayer = ({ nodes, xScale, yScale }) => {
    const lineGenerator = line()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y));

    let result = [];
    for (const [index, item] of standardData.entries()) {
      const itemData = item.data.map((d) => ({
        x: d.x,
        y: d.y,
      }));
      const standardsLine = lineGenerator(itemData);
      // console.log("standardsLine", standardsDataData);
      if (!standardsLine) {
        continue;
      }

      result.push(
        <path
          key={item.id}
          d={standardsLine}
          fill="none"
          stroke={`rgba(0, 0, 0, ${0.3 + 0.3 * index})`}
          strokeDasharray="5,5"
          strokeWidth={2}
        />
      );
    }

    return <g>{result}</g>;
  };

  return (
    <div
      className={`relative ${
        settings?.widgetHeight === "large" ? "h-[512px]" : "h-[256px]"
      } w-full`}
    >
      <ResponsiveLine
        data={data}
        theme={theme}
        curve={curveType}
        animate={true}
        enableSlices="x"
        enableGridX={false}
        enableGridY={false}
        margin={{
          top: 50,
          right: 20,
          bottom: settings?.showBottomAxisLabel ? 60 : 40,
          left: settings?.showLeftAxisLabel ? 60 : 40,
        }}
        xScale={{
          type: "linear",
          // type: "symlog",
          // type: "log",
          // base: 5,
          min: "auto",
          max: "auto",
        }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        // yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: settings?.showBottomAxisLabel
            ? attrs.axis?.bottom?.legend
            : null,
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 3,
          tickRotation: 0,
          legend: settings?.showLeftAxisLabel ? attrs.axis?.left?.legend : null,
          legendOffset: -50,
          legendPosition: "middle",
        }}
        colors={
          !!settings?.colors
            ? Array.from(settings?.colors)
            : DEFAULT_CHART_COLORS
        }
        // pointSymbol={CustomSymbol}
        enablePoints={false}
        pointSize={12}
        pointBorderWidth={1}
        pointBorderColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        pointLabelYOffset={-10}
        enablePointLabel={true}
        enableArea={true}
        useMesh={true}
        defs={[
          linearGradientDef("gradientA", [
            { offset: 0, color: "inherit" },
            { offset: 100, color: "inherit", opacity: 0 },
          ]),
        ]}
        fill={[{ match: "*", id: "gradientA" }]}
        areaBaselineValue={areaBaseLineValue}
        sliceTooltip={(props) => {
          const { slice } = props;
          const tooltips = [];

          for (const point of slice.points) {
            const tooltipMetrics = point.data.tooltip;
            const title = keys.find((k) => k.id === point.serieId).title;
            tooltips.push(
              <Tooltip key={point.id} color={point.color} className="mb-2">
                <TooltipItem
                  key={title}
                  label={title}
                  value={point.data.yFormatted}
                />
                {!isNullEmptyOrWhitespace(tooltipMetrics) &&
                  Object.entries(tooltipMetrics)?.map(([key, value]) => (
                    <TooltipItem key={key} label={key} value={value} />
                  ))}
              </Tooltip>
            );
          }

          return tooltips;
        }}
        legends={[
          {
            dataFrom: "keys",
            data: data.map((d, index) => ({
              id: d.id,
              label: keys.find((k) => k.id === d.id).title,
              color: !!settings?.colors
                ? settings.colors[index % settings.colors.length]
                : DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
            })),
            anchor: "top-left",
            direction: "row",
            justify: false,
            translateX: -30,
            translateY: -30,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 125,
            itemHeight: 20,
            itemOpacity: 0.85,
            symbolSize: 14,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            toggleSerie: true,
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        layers={[
          "grid",
          "markers",
          "axes",
          "areas",
          LineLayer,
          "crosshair",
          "lines",
          "points",
          "slices",
          "mesh",
          "legends",
        ]}
      />
    </div>
  );
};
