/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "lucide-react";
import classNames from "classnames";

export interface ISlideoverProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
  position?: "left" | "right";
  open: boolean;
  setOpen: (open: boolean) => void;
  unMountOnClose?: boolean;
}

export default function Slideover(props: ISlideoverProps) {
  const open = props.open || false;
  const unMountOnClose = props.unMountOnClose || true;

  return (
    <Transition.Root show={open} as={Fragment} unmount={unMountOnClose}>
      <Dialog
        as="div"
        className={classNames(props.className, "relative z-30")}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-200/75 dark:bg-gray-700/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={classNames(
                "pointer-events-none fixed inset-y-0 flex max-w-full",
                props.position === "right" ? "right-0 pl-10" : "left-0 pr-10"
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-500 tablet:duration-700"
                enterFrom={classNames(
                  "opacity-0",
                  props.position === "right"
                    ? "translate-x-full"
                    : "-translate-x-full"
                )}
                enterTo="translate-x-0 opacity-100"
                leave="transition ease-in-out duration-500 tablet:duration-700"
                leaveFrom="translate-x-0 opacity-100"
                leaveTo={classNames(
                  "opacity-0",
                  props.position === "right"
                    ? "translate-x-full"
                    : "-translate-x-full"
                )}
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                  <div className="flex h-full flex-col overflow-y-auto bg-primary-darkest2 dark:bg-gray-800 shadow-xl border-r border-primary-darker dark:border-gray-900">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title>{props.title}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="absolute -right-10 top-3 text-gray-600 hover:text-primary focus:outline-none focus:ring-0"
                            onClick={() => props.setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <X className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0">
                        <div
                          className="h-full flex flex-col"
                          aria-hidden="true"
                        >
                          {props.children}
                        </div>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
