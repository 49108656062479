import useFetch from "./useFetch";
import { useCallback, useEffect, useState } from "react";
import { sqlDateObjectFromServerTZ } from "helpers/dateUtilities";

type useFarmScheduleGetManyProps = {
  enabled?: boolean;
};

export const useFarmScheduleGetMany = ({
  enabled = true,
}: useFarmScheduleGetManyProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch();

  const [schedules, setSchedules] = useState([]);

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/schedules-get");

    // Change all server datetimes to local date object, making comparison easier going forward
    const updatedData = (data ?? []).map((record: { CompletedDate: any }) => {
      return {
        ...record,
        _CompletedDate: sqlDateObjectFromServerTZ(record.CompletedDate),
      };
    });

    setSchedules(updatedData);

    return updatedData;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, schedules };
};
