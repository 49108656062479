import React from "react";
import classNames from "classnames";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  name: string;
  inputClassName?: string;
  labelClassName?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, inputClassName, labelClassName, ...props }, ref) => {
    return (
      <div className="relative">
        {label && (
          <label
            htmlFor={props.id || props.name}
            className={classNames(
              "absolute -top-2 left-2 z-10 inline-block bg-white px-1 text-xs font-medium text-gray-500",
              labelClassName
            )}
          >
            {label}
          </label>
        )}
        <input
          {...props}
          ref={ref} // forwarding the ref to the input element
          className={classNames(
            "block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 tablet:text-sm tablet:leading-6",
            inputClassName
          )}
        />
      </div>
    );
  }
);

export default Input;
