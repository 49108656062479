import { useUser } from "hooks/useUser";

export default function Error500() {
  const { onSignOut } = useUser();

  function handleRefreshPage() {
    // ensure that we aren't refreshing error page
    if (window?.location?.pathname === "/500") {
      window.location.href = "/";
      return;
    }
    window.location.reload();
  }

  function handleRedirectToHomepage() {
    window.location.href = "/";
  }

  function handleRedirectToLogin() {
    onSignOut();
    window.location.href = "/user/login";
  }

  return (
    <div
      id="error-500"
      className="bg-gray-100 min-h-screen font-sans text-base flex flex-col overflow-x-hidden print:bg-white"
    >
      <div className="flex flex-col flex-grow justify-center py-4 px-4 tablet:text-center">
        <h1 className="text-3xl mb-3">500 Error</h1>
        <p className="mb-3">
          An error occurred while fetching application data.
        </p>
        <p className="mb-3">
          Click to{" "}
          <span
            className="text-primary cursor-pointer"
            onClick={handleRefreshPage}
          >
            refresh the page
          </span>{" "}
          and try again,{" "}
          <span
            className="text-primary cursor-pointer"
            onClick={handleRedirectToHomepage}
          >
            vist the homepage
          </span>{" "}
          or return to the{" "}
          <span
            className="text-primary cursor-pointer"
            onClick={handleRedirectToLogin}
          >
            login screen
          </span>
          .
        </p>
        <p className="mb-3 font-medium italic">
          If the issue persists, please contact your support team.
        </p>
      </div>
    </div>
  );
}
