import { useMenus } from "hooks/useMenu";
import { useUser } from "hooks/useUser";
import { buildListPageUrl } from "helpers/redirectUtilities";
import { useActiveMenu } from "hooks/useActiveMenu";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { List, ListItem } from "./core";
import ListSkeleton from "./core/Lists/ListSkeleton";
import { IMenuItem } from "./Sidebar";

interface RecentVisitsProps {
  className?: string;
}

export default function RecentVisits({ className }: RecentVisitsProps) {
  const { activeMenu } = useActiveMenu();
  const { menus } = useMenus();
  const navigate = useNavigate();

  const { user } = useUser();

  const recentVisits = useMemo(() => {
    let result: IMenuItem[] = [];
    if (menus === undefined || user?.username === undefined || activeMenu?.ID === undefined) {
      return result;
    }

    function getRecentVisitsFromLocalStorage(userName: string) {
      const recentVisits = localStorage.getItem(`recentVisits-${userName}`);
      return JSON.parse(recentVisits || "[]");
    }

    const recentVisits = getRecentVisitsFromLocalStorage(user.username);

    // menus to array of ID keys
    const menuIdsLookup = new Map<string, IMenuItem>();
    for (const parentMenu of menus) {
      if (parentMenu.Children === undefined) {
        continue;
      }
      for (const menu of parentMenu.Children) {
        menuIdsLookup.set(menu.ID, { ...menu, Parent: parentMenu });
      }
    }

    const newRecentVisits = recentVisits
      .map((visit: any) => {
        const menu = menuIdsLookup.get(visit);
        if (menu === undefined) {
          return undefined;
        }

        const result = menu;

        return result;
      })
      .filter((visit: any) => visit !== undefined && visit.ID !== activeMenu.ID);

    return newRecentVisits;
  }, [menus, activeMenu?.ID, user?.username]);

  function handleOnClickRecentVisit(visit: any) {
    return navigate(buildListPageUrl(visit));
  }

  return recentVisits.length > 0 ? (
    <List size="small" theme="striped" className={className}>
      {recentVisits.map((visit: IMenuItem) => (
        <ListItem key={visit.ID} onClick={() => handleOnClickRecentVisit(visit)}>
          <span>{visit.Parent?.Title}</span>{" "}
          <span className="text-gray-300">&nbsp;/&nbsp;</span>{" "}
          <span className="text-primary">{visit.Title}</span>
        </ListItem>
      ))}
    </List>
  ) : (
    <ListSkeleton />
  );
}
